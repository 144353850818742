<template>
  <svg
    width="21px"
    height="23px"
    viewBox="0 0 21 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>DF72FA96-ED73-4ECA-B829-7C63507884F6</title>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="00_MovingLayers_UI_Design-System"
        transform="translate(-276.000000, -3834.000000)"
        :stroke="isActive ? '#E2FF2A' : '#FFFFFF'"
        stroke-width="1.5"
      >
        <g
          id="Icon-/-Bestandsaufnahmen"
          transform="translate(274.000000, 3833.000000)"
        >
          <g
            id="Group-3"
            transform="translate(3.000000, 2.000000)"
          >
            <polygon
              id="Stroke-1"
              points="0 21 19 21 19 0 0 0"
            />
            <g
              id="Group"
              transform="translate(3.000000, 4.000000)"
            >
              <line
                id="Stroke-3"
                x1="0"
                y1="0.423076923"
                x2="13"
                y2="0.423076923"
              />
              <line
                id="Stroke-4"
                x1="0"
                y1="5.5"
                x2="13"
                y2="5.5"
              />
              <line
                id="Stroke-5"
                x1="-3.65263375e-14"
                y1="10.5769231"
                x2="6.5"
                y2="10.5"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>


<script>

export default {
  name: 'ToolbarListIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
