
import { environment } from "../../environments/environment";

export const isDevEnvironment = () => {
    const backendUrl = environment.BACKEND_URL;
    return (
        backendUrl.includes('localhost') ||
        backendUrl.includes('hzba-uat') ||
        backendUrl.includes('hzba-dev') ||
        backendUrl.includes('acon360-dev') ||
        backendUrl.includes('acon360-uat')
    )
}