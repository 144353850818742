<template>
  <div class="wrapper flex-1 grow inline-block text-right">
    <div
      class="flex-1 text-right inline text-sm input"
      data-cy="button-selectLanguage-en"
      @click="()=>setLang('en')"
    >
      {{ $t("locale.en") }}
    </div>, 
    <div
      class="flex-1 text-right inline text-sm input"
      data-cy="button-selectLanguage-de"
      @click="()=>setLang('de')"
    >
      {{ $t("locale.de") }}
    </div>
  </div>
</template>

<script>
import { IonButton } from "@ionic/vue";
import { useStore } from "@/composables/useTypedStore";
import AFlatButton from "@/components/Base/AFlatButton";
export default {
  name: "SelectLanguage",
  props: {},
  setup() {
    const store = useStore();
    function setLang(newLanguage) {
      store.commit("app/setLocale", newLanguage);
      location.reload();
    }
    return {
      setLang,
    };
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  border: none;
  border-radius: 8px;
  padding: 2px 4px;
  font-size: 12px;
  min-width: 35px;
  min-height: 20px;
  //color: white;
}

.input {
  color: $mlGrey;
}

</style>