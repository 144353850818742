import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-4" }
const _hoisted_2 = {
  key: 0,
  class: "px-4"
}
const _hoisted_3 = { class: "px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hzba_separator = _resolveComponent("hzba-separator")!
  const _component_AButton = _resolveComponent("AButton")!
  const _component_MangelItemTemplate = _resolveComponent("MangelItemTemplate")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_hzba_separator, null, {
      default: _withCtx(() => [
        _createTextVNode("Zusätzliche Mängel")
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localMangels, (mangel, key) => {
        return (_openBlock(), _createBlock(_component_MangelItemTemplate, {
          key: key,
          collapsed: mangel.collapsed,
          "onUpdate:collapsed": ($event: any) => ((mangel.collapsed) = $event),
          "is-custom-mangel": true,
          errors: mangel.errors($setup.t),
          mangel: mangel,
          "mangel-config": $props.config
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_AButton, {
                "btn-secondary": true,
                fill: "flat",
                "data-cy": "btn-delete-custom-mangel",
                onClick: () => $setup.removeMangelzuordnung(mangel)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Löschen ")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_AButton, {
                "btn-secondary": true,
                fill: "flat",
                "data-cy": "btn-update-custom-mangel",
                onClick: () => $setup.updateMangelzuordnung(mangel, key)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Bestätigen ")
                ]),
                _: 2
              }, 1032, ["onClick"])
            ])
          ]),
          _: 2
        }, 1032, ["collapsed", "onUpdate:collapsed", "errors", "mangel", "mangel-config"]))
      }), 128))
    ]),
    (!$setup.addMangelBox && !$setup.isReadonly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AButton, {
            "btn-primary": true,
            fill: "flat",
            "data-cy": "btn-add-custom-mangel",
            onClick: $setup.toggleAddMangelBox
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Mangelzuordnung hinzufügen ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    ($setup.addMangelBox)
      ? (_openBlock(), _createBlock(_component_MangelItemTemplate, {
          key: 1,
          mangel: $setup.newMangel,
          errors: $setup.newMangelErrors,
          "is-custom-mangel": true,
          "is-custom-mangel-add-mode": true,
          "mangel-config": $props.config
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AButton, {
                "btn-secondary": true,
                fill: "flat",
                "data-cy": "btn-submit-custom-mangel",
                onClick: $setup.addMangelzuordnung
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Bestätigen ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_AButton, {
                "btn-secondary": true,
                fill: "clear",
                color: "dark",
                "data-cy": "btn-cancel-custom-mangel",
                onClick: $setup.toggleAddMangelBox
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Abbrechen ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["mangel", "errors", "mangel-config"]))
      : _createCommentVNode("", true),
    _createVNode(_component_hzba_separator)
  ]))
}