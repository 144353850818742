import { logger } from "@/utilities/logging";
import axios from "axios";
import { environment } from "../../environments/environment";
import useAuth from "@/composables/useAuth";


export const strapiUrl = `${environment.BACKEND_URL}:${environment.BACKEND_PORT}`;
export const strapiApiUrl = `${strapiUrl}/api`;


export const createNewAxios = () => {
    return axios.create({
        baseURL: strapiApiUrl,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        timeout: 0,
    });
}

const apiClient = createNewAxios();

apiClient.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error) {
        console.error("#error response", error.response, error);

        if (!error.response) {
            error.status = 'Network Error';
        }

        throw error;
    }
);

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token && useAuth().isTokenExpired(token)) {
        localStorage.removeItem("token")
    }

    if (token && token !== "undefined") {   // Token is sometimes returned as "undefined" which leads to errors
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})


export const vuexORMAxiosSettings = () => {
    return {
        axios,
        persistBy: 'insert',
        headers: {},
        baseURL: `${environment.BACKEND_URL}:${environment.BACKEND_PORT}/api`,
        timeout: 0,
    }
};

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});

export default apiClient;
