<template>
  <BaseModal
    data-cy="IframeGalleryModal"
    :title="title"
    hide-save
    go-back-mode
    @close="() => cancel()"
  >
    <ion-content
      data-cy="IframeGalleryContent"
    >
      <iframe
        frameborder="0"
        allow="xr-spatial-tracking"
        :src="`${iframe}&play=1`"
        width="100%"
        height="100%"
        allowfullscreen
      />
    </ion-content>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/Base/BaseModal";
import useBaseModal from "@/composables/useModal";
import { IonImg, IonContent } from "@ionic/vue";
import { getPhotoUrl } from "@/utilities/get-media-url";

export default {
  name: "IframeGalleryModal",
  components: { BaseModal, IonImg, IonContent },
  props: {
    title: {
      type: String,
      default: ''
    },
    iframe: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'save'],
  setup() {

    const { defaultCancel } = useBaseModal();

    return {
      cancel: defaultCancel,
      getPhotoUrl,
    }
  }
}
</script>

<style scoped>

</style>