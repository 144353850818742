import { lang } from "@/models/localization.model";

export const app = {
  namespaced: true,
  state: () => ({
    locale: 'de' as lang,
    showBottomNavigation: false,
    screenWidth: -1,
    screenHeight: -1,
    networkConnected: undefined,
    recentRoute: undefined,
    deviceId: undefined,
    consoleLogs: [],
    lastBestandsaufnahmesBackendRefresh: undefined,
    showAppLinkBanner: false,
    welcomeAnimationDone: true,
    enabledDevMode: false, // for debugging only
    enabledDevModeLogging: false, // for better logs set this to true.
    disabledDevOTA: false, // always set to false when deploying to dev/uat/prod
  }),
  mutations: {
    showBottomNavigation(state: any) {
      state.showBottomNavigation = true;
    },
    hideBottomNavigation(state: any) {
      state.showBottomNavigation = false;
    },
    setScreenSize(state: any, { width, height }: { width: number; height: number }) {
      state.screenWidth = width;
      state.screenHeight = height;
    },
    setNetworkState(state: any, networkConnected: boolean) {
      state.networkConnected = networkConnected;
    },
    setRecentRoute(state: any, from: any) {
      state.recentRoute = from;
    },
    setDeviceId(state: any, from: any) {
      state.deviceId = from;
    },
    setShowAppLinkBanner(state: any, bool: any) {
      state.showAppLinkBanner = bool;
    },
    setLocale(state: any, lang: lang) {
      localStorage.setItem('locale', lang);
      state.locale = lang;
      console.log('[app/setLocale] selected language:', lang);
    },
    updateBestandsaufnahmesLastRefresh(state: any) {
      state.lastBestandsaufnahmesBackendRefresh = new Date();
    },

    addConsoleLog(state: any, from: any) {
      state.consoleLogs.push({
        date: new Date(),
        logs: from
      });
    },
    setWelcomeAnimationDone(state: any, bool: boolean) {
      state.welcomeAnimationDone = bool;
    }
  },
  getters: {
    isOffline(state: any, getters: any, rootState: any): boolean {
      return !state.networkConnected;
    },
    isOnline(state: any, getters: any, rootState: any): boolean {
      return state.networkConnected;
    },
    getLocale(state: any, getters: any, rootState: any): lang {
      return state.locale;
    },
    deviceId(state: any, getters: any, rootState: any): boolean {
      return state.deviceId;
    },
  },
};
