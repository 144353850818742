import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col mt-8 gap-1 px-4" }
const _hoisted_2 = { class: "m-0 text-lg" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex gap-4 no-scrollbar py-3 bg-white flex-wrap sticky top-0 z-10" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ba_difference = _resolveComponent("ba-difference")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      _createTextVNode(" Gefundene Änderungen"),
      (_ctx.activeTab != '')
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " im " + _toDisplayString(_ctx.activeTab), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, ": "))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(`flex-shrink-0 bg-gray-200 px-4 py-1 rounded-full cursor-pointer snap-start ${_ctx.activeTab === '' ? 'bg-gray-500 text-white' : ''}`),
        onClick: _cache[0] || (_cache[0] = () => _ctx.activeTab = '')
      }, " Alle anzeigen ", 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.BaTabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tab,
          class: _normalizeClass(`flex-shrink-0 bg-gray-200 px-4 py-1 rounded-full cursor-pointer snap-start ${_ctx.activeTab === tab ? 'bg-gray-500 text-white' : ''}`),
          onClick: () => _ctx.activeTab = tab
        }, _toDisplayString(tab), 11, _hoisted_6))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.baDifferences, (difference, key) => {
      return (_openBlock(), _createBlock(_component_ba_difference, {
        key: key,
        difference: difference,
        "active-tab": _ctx.activeTab
      }, null, 8, ["difference", "active-tab"]))
    }), 128))
  ]))
}