<template>
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 25 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>5BB3AD40-C716-478C-8B8F-12C1B9596FF4</title>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="00_MovingLayers_UI_Design-System"
        transform="translate(-364.000000, -3833.000000)"
      >
        <rect
          fill="#FFFFFF"
          x="0"
          y="0"
          width="1440"
          height="4410"
        />
        <rect
          id="Rectangle"
          fill="#4532C9"
          x="196"
          y="3828"
          width="202"
          height="33"
        />
        <g
          id="Icon-/-Bestandsaufnahmen-Copy-2"
          transform="translate(364.000000, 3833.000000)"
          :stroke="isActive ? '#E2FF2A' : '#FFFFFF'"
          stroke-width="1.5"
        >
          <polygon
            id="Path-5"
            points="12.5 1.09790039 22 10 22 23 3 23 3 10"
          />
          <polyline
            id="Path-6"
            points="15 23 15 15 10 15 10 23"
          />
          <polyline
            id="Path-7"
            points="17.2424316 5.80761719 17.2424316 2.15991211 19.9445801 2.15991211 19.9445801 8.34228516"
          />
        </g>
      </g>
    </g>
  </svg>
</template>


<script>

export default {
  name: 'ImmobilienIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
