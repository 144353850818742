import apiClient from '@/api/api';
import { APhoto } from '@/models/photo/a-photo.model';
import CachedPhoto from '@/models/photo/cached-photo.model';
import { AxiosResponse } from 'axios';


/**
 *
 */
export interface APhotoUploadTask {
    image: APhoto;
    ref: string;
    field: string;

    api: string;
    status: ("preparing" | "uploading" | "success" | "error" | "uploaded");

    // Unless the instance has its own id we have to use a local uid.
    uid: string;

    // Instance id received after json was uploaded. e.g. id of frage, mangel, unterschrift.
    instanceId?: number | undefined;

    fileName?: string;
}

/**
 * Upload a file to the backend
 * @param file - the file/image in File or Blob format
 * @param ref - name of the collection, e.g. "heizungsbestandsaufnahme"
 * @param refId - the instance id of the collection, e.g. hzba.id
 * @param field - the field name where the file/image should be saved, e.g. hzba.unterschriftGutachterBild
 *
 */
export const uploadFile = async (file: File | Blob, ref: string, refId: string, field: string, fileName: string, source?: string, contentType?: string): Promise<AxiosResponse<any>> => {
    const formData = new FormData();
    formData.append("files", file, fileName);
    formData.append("ref", ref);
    formData.append("refId", refId);
    contentType && formData.append("Content-Type", contentType);
    source && formData.append("source", source)
    formData.append("field", field);

    return apiClient.post("/upload", formData);
}
export const uploadPhoto = async (photo: CachedPhoto, ref: string, refId: string, field: string, fileName: string, source?: string): Promise<AxiosResponse<any>> => {
    const photoData = photo.rawData ? photo.rawData : photo.webPath!
    const response = await fetch(photoData)
    const blob = await response.blob();
    const file = new File([blob], fileName, {
        type: blob.type
    });

    return new Promise<AxiosResponse>((resolve, reject) => {
        try {
            resolve(uploadFile(file, ref, refId, field, fileName, source))
        } catch (err) {
            console.error('Error while uploading a photo and reducing file size', err);
        }
    })

    // // @ts-ignore
    // console.log("Uploading Image", ref, refId, field, "--- blob:", !!blob, blob && blob.fileSize, blob && blob.size, photo.rawData);
    // return uploadFile(blob, ref, refId, field, fileName, source);
}

export const deleteFile = async (id: string) => {
    return apiClient.delete(`upload/files/${id}`)
}

export class BestandsaufnahmePhotoUploadTask {
    //
    // /**
    //  * Get promise of all ba photo upload tasks
    //  * @param updatedBa
    //  * @param store
    //  */
    // async uploadPhotos(updatedBa: Bestandsaufnahme, store: Store<any>): Promise<SubmitProgressReport> {
    //
    //     // const asyncTasks = this.uploadTasks
    //     //     .map(task => this.uploadPhotoTask(task, updatedBa, this.immobilie))
    //     //     .flat();
    //
    //     this.uploadTasks.forEach(el => {
    //         if (instanceOfPhoto(el.image)) {
    //             el.status = "uploading"
    //         }
    //     })
    //
    //     // const res = [];
    //     for (const task of this.uploadTasks) {
    //         try {
    //             const sub = await this.uploadPhotoTask(task, updatedBa, this.immobilie, store);
    //             console.log("NOW ---------------------")
    //             // res.push(sub)
    //         } catch (err) {
    //             console.error("uploadPhotos failed", err);
    //             return {
    //                 success: false,
    //                 message: `images failed to upload.`
    //             }
    //         }
    //     }
    //
    //     return { success: true }
    //
    //     // const failedImages = res.filter(el => el === false).length
    //     // if (failedImages > 0) {
    //     //     logger.error(`HZBA_LOGGING (FRONTEND) - Image upload failed: The upload failed for ${failedImages} images.`)
    //     //     return {
    //     //         success: false,
    //     //         message: `${failedImages} images failed to upload.`
    //     //     }
    //     // }
    //     // return { success: true }
    // }
    //
    // /**
    //  * Called after uploading the json (extracted from any photos)
    //  * Get one promise per photo
    //  * @param task
    //  * @param updatedHzba
    //  * @private
    //  */
    // private async uploadPhotoTask(task: APhotoUploadTask, updatedBa: Bestandsaufnahme, immobilie: Immobilie, store: any) {
    //
    //     // Either Frage.ts or Mangelzuordnung.ts
    //     let updatedInstance: {
    //         id?: number;
    //         eingabeMedien?: APhoto[] | null;
    //         bild?: APhoto | null;
    //         identifier?: string;
    //         name?: string;
    //     } | undefined;
    //
    //     let type = 'frageMangel'
    //
    //     switch (task.ref) {
    //         case 'frages':
    //             updatedInstance = updatedBa.getFrages().find(frage => frage.config?.photoUploadUid === task.uid);
    //             break;
    //         case 'mangels':
    //             updatedInstance = updatedBa.getFiredMaengel().find(frage => frage.config?.photoUploadUid === task.uid);
    //             break;
    //         case 'unterschrift':
    //             updatedInstance = updatedBa.unterschrifts?.find(u => u.uid === task.uid);
    //             type = 'unterschrift';
    //             break;
    //         default:
    //             throw 'task.ref is not defined in uploadPhotoTask'
    //     }
    //
    //     if (!updatedInstance) {
    //         console.error("Updated Instance was not found when uploading Photos (uploadPhotoTask)", task);
    //         throw 'could not find instance for photo';
    //     }
    //
    //     const photo = task.image;
    //
    //     // Already uploaded photo
    //     if (!instanceOfPhoto(photo)) {
    //         return true;
    //     }
    //
    //     let fileName;
    //     if (type === 'frageMangel') {
    //         fileName = `${immobilie?.strasse}_${immobilie?.plz}_${immobilie?.stadt}_${updatedBa.identifier}_${updatedInstance!.identifier || 'mangel'}.jpeg`;
    //     } else {
    //         fileName = `${immobilie?.strasse}_${immobilie?.plz}_${immobilie?.stadt}_${updatedBa.identifier}_unterschrift_${updatedBa.name}.jpeg`;
    //     }
    //
    //     try {
    //         // * Theoretically we could upload all photos of an entity at once
    //         // but then we cannot act upon a photo upload fail for one photo, just all photos combined.
    //         task.status = "uploading";
    //
    //         const upload = await uploadPhoto(photo as CachedPhoto, task.api, updatedInstance!.id!.toString()!, task.field, fileName);
    //         task.status = "success";
    //         if (upload.data) {
    //             await deletePhotoLocal(photo);
    //
    //             if (type === 'unterschrift') {
    //                 updatedInstance!.bild = upload.data && upload.data[0]
    //             } else {
    //                 if (!updatedInstance!.eingabeMedien) updatedInstance!.eingabeMedien = [];
    //                 updatedInstance!.eingabeMedien!.push(...upload.data);
    //             }
    //
    //             await store.dispatch("currentHzba/saveHzbaLocally");
    //
    //         } else {
    //             throw "Uploading Image successful but received no data"; // This will most likely never happen
    //         }
    //     } catch (error) {
    //         // The image upload was not successful, to not lose the images, we reattach them to the hzba object
    //         console.error("Image upload failed", error);
    //         task.status = "error";
    //         logger.error("HZBA_LOGGING (FRONTEND) - Photo upload failed with error", {error});
    //
    //         // if (type === 'frageMangel') {
    //         //     updatedInstance!.eingabeMedien?.push(photo);
    //         // } else {
    //         //     updatedInstance!.bild = photo;
    //         // }
    //
    //         throw "Uploading Image failed";
    //     }
    //     return true;
    // }
    //
    // reassignPhotosToFrages(updatedBa: Bestandsaufnahme) {
    //     console.log("Reassign photos!, updatedBa:", useIdentifierFrageFinder(updatedBa, 'hzba.uebersicht-tab.anlagenschema-group.anlagenschema'))
    //
    //     const frages = updatedBa.getFrages();
    //     const maengel = updatedBa.getFiredMaengel()
    //     this.uploadTasks.forEach((upload) => {
    //         let instance: any = frages.find(frage => frage.config?.photoUploadUid && frage.config?.photoUploadUid == upload?.uid);
    //
    //         if (!instance) {
    //             instance = maengel.find(frage => frage.config?.photoUploadUid && frage.config?.photoUploadUid == upload?.uid);
    //         }
    //
    //         if (instance) {
    //             if (upload.status !== "success" && upload.status !== "uploaded") {
    //                 instance.eingabeMedien.push(upload.image)
    //             }
    //         }
    //
    //         console.log("Reassign Photos, found instance", !!instance, instance)
    //
    //         if (!instance) {
    //             const unterschrifts = this.bestandsaufnahme.unterschrifts;
    //             instance = unterschrifts?.find(unter => unter.uid === upload?.uid)
    //
    //             console.log("try..", unterschrifts, upload?.uid, instance)
    //             if (instance) {
    //                 instance.bild = upload.image;
    //             }
    //         }
    //     })
    //
    //     console.log('Reassigned photos!, updated ba:', useIdentifierFrageFinder(updatedBa, 'hzba.uebersicht-tab.anlagenschema-group.anlagenschema'));
    // }
    //
    //
    // toJson() {
    //     return {
    //         uploadTasks: this.uploadTasks,
    //     }
    // }

}
