<template>
  <button class="bg-transparent">
    <slot />
  </button>
</template>

<script>

export default {
  name: 'AIconButton'
}


</script>

<style>
</style>