import Immobilie from '@/models/immobilie.model';
import { Coordinates, GeoJSONSource, GeolocateControl, LngLatBounds, Map, NavigationControl, } from 'maplibre-gl';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import mapStyleJson from '../../public/assets/geodata/style_ario.json';

export function usePropertyMap(mapRootId: string, properties: Immobilie[]) {

  const map = ref();
  let propertySource: any;

  // let propertyPointClickedListener: Function;
  // let propertyPointHoveredListener: Function;

  function setPropertySource( props: Immobilie[], propertyId?: number ): void {
    const workItemsGeoJSONPoints = props.filter(prop => prop.geolocationLon && prop.geolocationLat).map((property: Immobilie) => {
      if (property.geolocationLat !== null) {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [property.geolocationLon, property.geolocationLat],
          },
          properties: {
            itemData: property.id,
            selected: propertyId === property.id
          },
        };
      }
    });

    propertySource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: workItemsGeoJSONPoints,
      }
    };
  }

  function addPropertySource(): void{
    map.value.addSource('propertySource', propertySource)
  }


  function addPropertyLayer(): void {
    map.value.addLayer({
      id: 'property-points',
      type: 'circle',
      source: 'propertySource',
      paint: {
        'circle-radius': 6,
        "circle-stroke-width":[
          'case',
          ['==', ['get', 'selected'], true], 4.5,2.5
      ],
        'circle-color': [
            'case',
            ['==', ['get', 'selected'], true], '#4532C9',
            '#4532C9'
        ],
        "circle-opacity": 1,
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'selected'], true], '#E2FF2A','#ECECEC'
          
      ]
      }
    });
  }
  
  function getBounds(): LngLatBounds {
    const coordinates: Coordinates = propertySource.data.features.map((feature: any) => {
      return feature.geometry.coordinates;
    });

    const bounds: LngLatBounds = coordinates.reduce((bounds: any, coord: any) => {
        return bounds.extend(coord);
    }, new LngLatBounds(coordinates[0], coordinates[0]));

    return bounds;
  }

  function createMap(): void {
    map.value = new Map({
      container: mapRootId,
      maxZoom: 17.9,
      style: mapStyleJson,
    });
   
    map.value.addControl( new NavigationControl({}), 'top-left' );
    map.value.addControl( new GeolocateControl({}), 'top-left' );
  }

  async function animateToProperty(property: Immobilie): Promise<void> {
    const source: GeoJSONSource = map.value.getSource('propertySource') as GeoJSONSource;
    setPropertySource(properties, property.id);
    source.setData(propertySource.data); 
    map.value.flyTo({
      center: [property.geolocationLon, property.geolocationLat],
      zoom: 17.5,
      minZoom: 13,
      speed: 0.5,
      essential: true
    });
  }

  // const setPropertyPointClickedListener = (fctn: Function) => {
  //   propertyPointClickedListener = fctn;
  // }
  // const setPropertyPointHoveredListener = (fctn: Function) => {
  //   propertyPointHoveredListener = fctn;
  // }



  function updateProperties(props: Immobilie[]): void {
    setPropertySource(props);
    
    ( map.value.getSource('propertySource') as GeoJSONSource).setData(propertySource.data);
  }

  function reload(): void {
    map.value.resize();
  }

  onBeforeUnmount(() => {
    if ((document.getElementById(mapRootId)?.children?.length ?? 0) > 0) {
      document.getElementById(mapRootId)!.firstChild!.remove();
    }
  });

  onMounted(() => {
    setPropertySource( properties );
    createMap();

    map.value.once("load", () => {
      map.value.fitBounds(getBounds(), {
        padding: 100
      });
      addPropertySource();
      addPropertyLayer();

    });


  });

  return {
    animateToProperty,
    updateProperties,
    reload,
    map
  };
}
