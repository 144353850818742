<template>
  <ion-content
    v-if="user"
    class="ion-padding"
    style="background: white"
  >
    <div data-cy="user-name">
      {{ user.username }}
    </div>
    <div
      class="mb-4"
      data-cy="user-email"
    >
      <strong>{{ user.email }}</strong>
    </div>
    <!--    <ion-item class="cursor-pointer" @click="goToSettings">Einstellungen</ion-item>-->
    <div class="flex flex-col">
      <AButton
        data-cy="button-faq"
        :btn-primary="true"
        class="mb-2"
        :expand="true"
        @click="goToFaq"
      >
        {{ t('toolbar.hilfe') }}
      </AButton>
      <AButton
        data-cy="button-impressum"
        :btn-primary="true"
        class="mb-2"
        :expand="true"
        @click="goToImpressum"
      >
        {{ t('toolbar.impressum') }}
      </AButton>

      <AButton
        data-cy="button-logout"
        :btn-primary="true"
        class="mb-2"
        :expand="true"
        @click="logout"
      >
        {{ t('toolbar.ausloggen') }}
      </AButton>
    </div>
  </ion-content>
</template>

<script>
import { useI18n } from 'vue-i18n';
import {IonContent, IonItem, popoverController} from '@ionic/vue';
import { defineComponent } from 'vue';
import useAuth from "@/composables/useAuth";
import useUser from "@/composables/useUser";
import {useRouter} from "vue-router";
import AButton from "@/components/Base/AButton";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import {useBestandsaufnahmens} from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import useAlert from "@/composables/useAlert";
import useLogoutAlert from "@/composables/User/useLogoutAlert";
import { logger } from "@/utilities/logging";
// import AppLogger from "@/utilities/AppLogger";


export default defineComponent({
  name: 'UserPopover',
  components: {AButton, IonContent, IonItem },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const auth = useAuth();
    const { user } = useUser();
    const router = useRouter();

    function dismiss() {
      popoverController.dismiss();
    }


    const logout = async () => {
      await useLogoutAlert(router, t);
      // logger.info('{"Tag": "ACON360_CRITICAL_ERROR ACON360_LOGGING (FRONTEND)", "Message": `"User ${user}"`}');
      // AppLogger.info('{"message from AppLogger.info": "user logged out"}');

      dismiss();
    }

    function goToSettings() {
      router.push('/settings')
      dismiss();
    }

    function goToFaq() {
      router.push('/faq')
      dismiss();
    }

    function goToImpressum() {
      router.push('/impressum')
      dismiss();
    }

    return {
      logout,
      dismiss,
      user,
      goToSettings,
      goToFaq,
      goToImpressum,
      t
    }
  }
});
</script>

<style scoped>

ion-content {
  --background: white;
}
</style>