import {Mangelzuordnung} from "@/models/ba/Mangelzuordnung";

export default function useDownloadMaengel(fileName:String, firedMangels:any, downloadFile: Function, t: any) {
    
    const header = [
        t('hzba.maengelliste.mangelname'),
        t('hzba.maengelliste.maluspunkte'),
        t('hzba.maengelliste.freitext'),
        t('hzba.maengelliste.relevant'),
        t('hzba.maengelliste.gewerk'),
        t('hzba.maengelliste.handlungsbedarfZeitpunkt'),
        t('hzba.maengelliste.handlungsbedarfTaetigkeit'),
        t('hzba.maengelliste.kategorie'),
    ]

    const mapMangel = (mangel: Mangelzuordnung) => {
        return [
            mangel.titel(),
            mangel.maluspunkte(),
            mangel.eingabeFreitext,
            mangel.eingabeRelevant,
            mangel.gewerk(),
            mangel.handlungsbedarfZeitpunkt(),
            mangel.handlungsbedarfTaetigkeit(),
            mangel.kategorie(),
        ]
    }

    const rows = [
        header,
        ...firedMangels.map((el: any) => mapMangel(el))
    ];

    const csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(";")).join("\n");

    const encodedURI = encodeURI(csvContent);
    const fixedEncodedURI = encodedURI.replaceAll('#', '%23');

    downloadFile(
        fixedEncodedURI,
        fileName
    );
}