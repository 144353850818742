<template>
  <div 
    class="pt-2 pb-2 rounded-md"
    :class="[
      type === 'warning' ? 'warning' : '',
      type === 'success' ? 'success' : '',
    ]"
  >
    <div
      class="mx-4"
      :class="noExtraMargin ? '' : 'my-4'"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoBanner",
  props: {
    type: {
      type: String,
      default: 'warning'
    },
    noExtraMargin: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

.warning {
  background: #f6df6d;
}

.success {
  background: #2dd36f;
  color: #0f4e28;
}


</style>