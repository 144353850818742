import { useProperties } from "@/composables/Property/useProperties";
import { useStore } from "@/composables/useTypedStore";
import { Network } from "@capacitor/network";

export const SCREEN_XL_START = 1400;
export const SCREEN_LG_START = 1024;

export default function useNetworkState() {

    const store = useStore();
    const { sendPersistedImages } = useProperties();
    const maxRetries = 5;
    const baseDelay = 2000;
    let retryCount = 0;
    let isPhotoSendingInProgress = false;
    
    async function sendData( ) {
        try {
            isPhotoSendingInProgress = true
            console.log("Attempting to send data.");

            await sendPersistedImages();
            console.log("Data sent successfully.");

            isPhotoSendingInProgress = false
            retryCount = 0;
        } catch (error) {
            if (retryCount < maxRetries) {
                retryCount++;
                const delay = baseDelay + retryCount * 1000; // Exponential backoff

                console.error(`Error sending data. Retrying in ${delay}ms.`, error);
                setTimeout(() => sendData(), delay);
            } else {
                console.error('Max retries reached. Giving up.');
                retryCount = 0;
                isPhotoSendingInProgress = false
            }
        }
    }
    /**
     * Call this function once, e.g. in App.vue
     */
    async function mountMe() {
        const networkStatus = await Network.getStatus();
        store.commit("app/setNetworkState", networkStatus.connected);

        Network.addListener("networkStatusChange", (status: any) => {
            store.commit("app/setNetworkState", status.connected);
            
            if (!isPhotoSendingInProgress && status.connected) {
                setTimeout(() => sendData(), baseDelay);            }
        });
    }

    async function unmountMe() {
        await Network.removeAllListeners();
    }

    return {
        mountMe,
        unmountMe,
    }

}