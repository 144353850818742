<template>
  <ion-content
    class="ion-padding"
    data-cy="selector-add-item"
  >
    <ion-item
      v-for="item in items"
      :key="item"
      class="cursor-pointer"
      @click="chooseItem(item)"
    >
      {{ item }}
    </ion-item>
  </ion-content>
</template>

<script>
import {IonContent, IonItem, popoverController} from '@ionic/vue';
import { defineComponent } from 'vue';
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: 'AddElementPopover',
  components: { IonContent, IonItem },
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  setup() {
    const auth = useAuth();

    function dismiss(item) {
      popoverController.dismiss(item);
    }

    function chooseItem(item) {
      dismiss(item);
    }

    return {
      chooseItem,
      dismiss
    }
  }
});
</script>