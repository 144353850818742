<template>
  <ion-page>
    <div />
  </ion-page>
</template>

<script>
import useAuth from "../composables/useAuth";
import IonPage from "@/App";

export default {
  name: "Logout",
  components: {IonPage},
  setup() {
    useAuth().logout();
  }
}
</script>

<style scoped>

</style>