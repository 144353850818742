<template>
  <div
    class="text-xxs mt-1 absolute bottom-4"
    data-cy="pdf-color-codes"
  >
    <div>
      <div class="flex items-center w-full">
        <div>{{ t("hzba.pdf.farbcodierung", 1, { locale: lang }) }}&nbsp;</div>
        <div class="flex items-center mx-1">
          <PdfChip
            color="green"
            pdf-mode
          />
          {{ t("hzba.pdf.keineMaengel", 1, { locale: lang }) }}&nbsp;&nbsp;
        </div>
        <div class="flex items-center mx-1">
          <PdfChip
            color="blue"
            pdf-mode
          />
          {{
            t("hzba.pdf.keineRelevantenMaengel", 1, { locale: lang })
          }}&nbsp;&nbsp;
        </div>
        <div class="flex items-center mx-1">
          <PdfChip
            color="yellow"
            pdf-mode
          />
          {{
            t("hzba.pdf.versorgungsrelevanteMaengel", 1, { locale: lang })
          }}&nbsp;&nbsp;
        </div>
        <div class="flex items-center mx-1">
          <PdfChip
            color="red"
            pdf-mode
          />
          {{
            t("hzba.pdf.sicherheitsrelevanteMaengel", 1, { locale: lang })
          }}&nbsp;&nbsp;
        </div>
      </div>
      <div class="flex items-center w-full">
        <!-- <PdfChip color="blue" /> {{ t("hzba.pdf.keineRelevantenMaengel", 1, { locale: lang}) }} -->
      </div>
      <div class="flex items-center w-full">
        <!-- <PdfChip color="yellow" />
        {{ t("hzba.pdf.sicherheitsrelevanteMaengel", 1, { locale: lang }) }} -->
      </div>
      <div class="flex items-center w-full">
        <!-- <PdfChip color="red" />
        {{ t("hzba.pdf.versorgungsrelevanteMaengel", 1, { locale: lang }) }} -->
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import { defineComponent } from "vue";
export default defineComponent({
  name: "PdfColorCodes",
  components: { PdfChip },
  props: {
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(_, params) {
    const { t } = useI18n({ useScope: "global" });

    return {
      t,
    };
  },
});
</script>

<style scoped>
</style>