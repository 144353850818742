import { useIdentifierFrageFinder } from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Frage } from "@/models/ba/Frage";
import { ToJsonSettings } from "@/models/ba/interfaces/IBaNode";
import { store } from "@/store/store";
import * as turf from "@turf/turf";
import moment from "moment";

export interface LogikElementFlatJson {
    createdAt?: any;
    updatedAt?: any;
    decimals?: string;
    pfad?: string;
    wert?: string;
    op?: string;
    ergebnisWert?: string;
}
export interface LogikElementJson extends LogikElementFlatJson {
    logikElements?: LogikElementJson[];
}


export class LogikElement implements LogikElementFlatJson{

    public createdAt?: any;
    public updatedAt?: any;
    public wert: any;
    public pfad?: any;
    public decimals?: string;
    public logikElements?: LogikElement[];
    public op?: string;
    public ergebnisWert?: string;
    public currentLogicElement?: Frage;
    private currentPath?: string;
    private mode?: number;

    private tmpFired?: boolean;

    constructor(json: LogikElementJson) {
        this.wert = json.wert;
        this.createdAt = json.createdAt;
        this.updatedAt = json.updatedAt;
        this.pfad = json.pfad;
        this.wert = json.wert;
        this.decimals = json.decimals;
        this.op = json.op;
        this.ergebnisWert = json.ergebnisWert;
        this.logikElements = json.logikElements?.map(el => new LogikElement(JSON.parse(JSON.stringify(el))))
    }


    public setupFireDetector(currentPath: string, mode?: number) {
        this.currentPath = currentPath;
        this.mode = mode;

        const ba: Bestandsaufnahme | undefined = store.getters['currentHzba/getHzbaFromMode'](mode);
        if (!ba) {
            return console.error('Error on setupFireDetector: BA is undefined. Mode: ' + mode, "Path", currentPath)
        }


        const parentPath = currentPath.substr(0, currentPath.lastIndexOf("."));

        let targetPath = '';
        if (!this.pfad) { targetPath = currentPath; }
        if (targetPath === '') {
            const isGlobalPath = !this.pfad.startsWith('./');

            if (isGlobalPath) {
                targetPath = this.pfad;
            } else {
                // relative path
                targetPath = parentPath + "." + this.pfad.replace('./', '');
            }
        }

        // console.log("Path setup: ", this.pfad, " || ",  targetPath, " || " , currentPath);

        const foundEl = useIdentifierFrageFinder(ba, targetPath, currentPath);

        // console.log("LogikElement found el", foundEl, targetPath, currentPath);

        if (!foundEl) {
            return console.error(`Mangelzuordnung: Element not found with path: ${targetPath} called from Path ${currentPath}. Current Logic Element:`, this)
        }

        this.currentLogicElement = foundEl;

        this.logikElements?.forEach(el => el.setupFireDetector(currentPath, mode))
    }

    public isFired(): boolean {

        const inputEnabled = !!this.currentLogicElement?.getCurrentInput;
        const currentInput = this.currentLogicElement?.getCurrentInput && this.currentLogicElement?.getCurrentInput();


        if (!this.op || ['!==', '>=', '>', '<=', '<'].includes(this.op)) {

            if (this.logikElements) {
                if (this.logikElements.length === 2) {

                    const l1 = this.logikElements[0].calculateComputedValue();
                    const l2 = this.logikElements[1].calculateComputedValue();
                    if (!l1 || !l2) {
                        this.tmpFired = false;
                        return false;
                    }

                    const res = compareFloats(l1, l2, this.op, { targetPath: this.pfad, currentPath: this.currentPath });
                    this.tmpFired = res;
                    return res;
                } else {
                    console.error('Operators like >=, >, <=, < that have children should have exactly 2 children.');

                    this.tmpFired = false;
                    return false;
                }

            }
            // if there is no input yet, do not throw an error immediately
            if (currentInput === undefined || currentInput === null) {
                this.tmpFired = false;
                return false;
            }

            if (!inputEnabled) {
                console.error('LogikElement Fire Error: Operator was not set but it seems that the target Element is not type of Frage (but it`s probably type of Fragenblock instead) ' +
                    `We cannot assume the default operator "equals" to this. Target path: ${this.pfad}, called from path ${this.currentPath}`)
                console.error('CurrentLogicElement', this.currentLogicElement);
                this.tmpFired = false;
                return false;
            }


            const currentInputString = currentInput.toString();
            const wertString = this.wert !== undefined && this.wert !== null && this.wert.toString();

            if (!this.op || this.op === '!==') {
                if (Array.isArray(currentInput)) {
                    const res = !this.op ? currentInput.includes(wertString) : !currentInput.includes(wertString) ;
                    this.tmpFired = res;
                    return res;
                } else {
                    const res = !this.op ? currentInputString === wertString : currentInputString !== wertString;
                    this.tmpFired = res;
                    return res;
                }
            }

            if (typeof this.wert === 'object') {
                if (this.wert.format === 'date' || this.wert.format === 'year') {

                    const res = compareDates(currentInputString, this.wert, this.op, this.mode);
                    this.tmpFired = res;
                    return res;
                }

                console.error(`LogikElement error: Wert is an object but has an unknown format called '${this.wert.format}'`)
                this.tmpFired = false;
                return false;
            }


            const res = compareFloats(currentInputString, wertString, this.op, { targetPath: this.pfad, currentPath: this.currentPath});
            this.tmpFired = res;
            return res;
        }

        if (this.op.toLowerCase() === 'progress_complete') {
            // @ts-ignore - TODO enable fragenblock for currentLogicElement
            const res = Math.round(this.currentLogicElement?.getProgress()) === 100;
            this.tmpFired = res;
            return res;
        }

        if (this.op.toLowerCase() === 'or') {
            let ret = false;
            this.logikElements?.forEach(el => {
                if (el.isFired()) {
                    ret = true;
                    return;
                }
            })
            this.tmpFired = ret;
            return ret;
        }

        if (this.op.toLowerCase() === 'and') {
            let ret = true;
            this.logikElements?.forEach(el => {
                if (!el.isFired()) {
                    ret = false;
                    return;
                }
            })
            this.tmpFired = ret;
            return ret;
        }

        if (this.op.toLowerCase() === 'not') {
            // this operator only supports one child

            if (this.logikElements && this.logikElements.length === 1) {

                this.tmpFired = !this.logikElements[0].isFired();
                return this.tmpFired;
            }
            console.error(`Not operator is supposed to have exactly one child but has ${this.logikElements?.length}`)
            this.tmpFired = false;
            return false;
        }

        console.error(`Operator ${this.op} was not found in isFired()`)
        this.tmpFired = false;
        return false;
    }

    public calculateComputedValue(): any {
        if (!this.op) {
            const val = this.wert ? this.wert : this.currentLogicElement?.getCurrentInput();
            let returnValue;
            if ( val !== undefined && val !== null ) {
                returnValue = Number.parseFloat(val.toString())
                if ( isNaN(returnValue) ) {
                    returnValue = val;
                }
            }
            
            return returnValue;
        }

        if (this.op === '===') {
            if (this.logikElements && this.logikElements.length === 1) {
                const logikValue = this.logikElements[0].currentLogicElement?.getCurrentInput();
                if ( logikValue === undefined || logikValue === null ) { return undefined; }
                return logikValue === this.logikElements[0].wert ? this.ergebnisWert : undefined;
            } else {
                console.error('Operators like === should have exactly one child.')
                return undefined;
            }
        }

        if (this.op.toLowerCase() === '+') {
            let comp = 0; let doReturn = true;
            this.logikElements?.forEach(el => {
                const res = el.calculateComputedValue();
                if (!res) { doReturn = false; return; }
                comp += res;
            })
            return doReturn ? comp : undefined;
        }

        if (this.op.toLowerCase() === '-') {
            let comp: number | undefined = undefined; let doReturn = true;
            this.logikElements?.forEach(el => {
                const res = el.calculateComputedValue();
                if (!res) { doReturn = false; return; }
                if (!comp) {
                    comp = res
                } else {
                    comp -= res;
                }
            })
            return doReturn ? comp : undefined;
        }

        if (this.op.toLowerCase() === '*') {
            let comp = 1; let doReturn = true;
            this.logikElements?.forEach(el => {
                const res = el.calculateComputedValue();
                if (!res) { doReturn = false; return; }
                comp *= res;
            })
            return doReturn ? round(comp, this.decimals) : undefined;
        }

        if (this.op.toLowerCase() === 'pow') {
            if (this.logikElements && this.logikElements.length === 2) {
                const l1 = this.logikElements[0].calculateComputedValue();
                const l2 = this.logikElements[1].calculateComputedValue();
                if (!l1 || !l2) { return undefined; }
                return round(Math.pow(l1, l2), this.decimals)
            } else {
                console.error('Operators like >=, >, <=, < that have children should have exactly 2 children.')
                return undefined;
            }
        }

        if (this.op.toLowerCase() === '/') {
            if (this.logikElements && this.logikElements.length === 2) {
                const l1 = this.logikElements[0].calculateComputedValue();
                const l2 = this.logikElements[1].calculateComputedValue();
                if (!l1 || !l2) { return undefined; }
                if (l2 === 0) {
                    console.error('LogikElement: Division by 0 is not allowed.')
                    return undefined;
                }
                return round(l1 / l2, this.decimals);
            } else {
                console.error('Operators like >=, >, <=, < that have children should have exactly 2 children.')
                return undefined;
            }
        }

        const getGeoInfo = (type: string, op: string) => {
            const coordinateType = op.toLowerCase() === 'geo-lat' ? 1 : 0;
            if (this.logikElements && this.logikElements.length > 0) {

            const features = this.logikElements[0]?.currentLogicElement?.eingabeJson?.features;
        
            if (features && features.length > 0) {
                const feature = features[0];
                if (feature.geometry.type === type) {
                    if (type === 'Polygon') {
                        return Math.round((turf.area(feature) / 1000000 + Number.EPSILON) * 100) / 100;
                    } else if (type === 'LineString') {
                        return Math.round((turf.length(feature) + Number.EPSILON) * 100) / 100;
                    } else if (type === 'Point') {
                        const coordinate = feature.geometry.coordinates?.[coordinateType] || 0.0;
                        return Math.round((coordinate + Number.EPSILON) * 100000) / 100000;
                    }
                }
            }
            return 0.0;
        } else {
            return 0.0
        }
        };
        
        if (this.op.toLowerCase() === 'geo-area') {
            return getGeoInfo('Polygon', this.op);
        }
        
        if (this.op.toLowerCase() === 'geo-length') {
            return getGeoInfo('LineString', this.op);
        }
        
        if (this.op.toLowerCase() === 'geo-lat' || this.op.toLowerCase() === 'geo-lng') {
            return getGeoInfo('Point', this.op);
        }
        
        
    
        console.error(`Operator ${this.op} was not found in calculateComputedValue()`)
        return undefined;

    }





    /**
     * Recursive Method to get the json of every subobject. Does not necessarily deep copy the whole object
     * as we still have nested objects here. Use copyJson() for deep copy.
     *
     * We do not copy variables that are used for state management,
     * We only copy those who are relevant for the backend.
     */
    public toClassJson(settings: ToJsonSettings = {}): LogikElementJson {
        const logikElements = this.logikElements?.map((el: LogikElement) => settings.cleanup ? el.cleanAndCopyJson() : el.copyJson())

        return {
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            ergebnisWert: this.ergebnisWert,
            op: this.op,
            decimals: this.decimals,
            logikElements: logikElements,
            pfad: this.pfad,
            wert: this.wert
        }
    }

    /**
     * Deep copy this class with all of it's subobjects.
     */
    public copyJson(settings: ToJsonSettings = {}): LogikElementJson { return JSON.parse(JSON.stringify(this.toClassJson(settings))); }

    /**
     * Deep copy this class with all of it's subobjects.
     * Use this if you want an object that is like the original
     * but without specific id's that makes the original instance unique (e.g. copy from template, duplicate)
     */
    public cleanAndCopyJson(): LogikElementJson { return JSON.parse(JSON.stringify(this.toClassJson({ cleanup: true } ))); }

}


function compareFloats(currentInput: any, targetInput: any, operator: any, debugInfo: any) {
    const currentInputFloat: any = currentInput && Number.parseFloat(currentInput.toString());
    const wertFloat = targetInput && Number.parseFloat(targetInput);

    if (!currentInputFloat || !wertFloat) {
        console.error(`LogikElements: Tried to parse ${currentInput} or ${targetInput} but failed to do so.`, debugInfo)
        return false;
    }

    switch (operator) {
        case '>=': return currentInputFloat >= wertFloat;
        case '>': return currentInputFloat > wertFloat;
        case '<=': return currentInputFloat <= wertFloat;
        case '<': return currentInputFloat < wertFloat;
        default:
            console.error('operator not defined ', operator, 'this is likely a bug occured in LogikElement.ts')
            return false;
    }

}
function compareDates(currentInput: any, targetInput: any, operator: any, mode?: any) {
    const currentInputDate = moment(currentInput);

    const ba: Bestandsaufnahme | undefined = store.getters['currentHzba/getHzbaFromMode'](mode);
    const targetInputDate = targetInput.wert ? moment(targetInput.wert) : moment(ba?.begehungsdatum);
    targetInputDate.add(targetInput.wertRelativ, 'years');

    if (!targetInput.wert && !ba?.begehungsdatum) {
        console.error(`LogikElement: Tried to use Bestandsaufnahme's Begehungsdatum but it is ${ba?.begehungsdatum}.`)
    }

    if (!currentInput || !targetInput) {
        console.error(`LogikElements: Tried to parse current input ${currentInput} or target path ${targetInput} but failed to do so.`)
        return false;
    }

    switch (operator) {
        case '>=': return currentInputDate.isSameOrAfter(targetInputDate);
        case '>': return currentInputDate.isAfter(targetInputDate);
        case '<=': return currentInputDate.isSameOrBefore(targetInputDate);
        case '<': return currentInputDate.isBefore(targetInputDate);
        default:
            console.error('operator not defined ', operator, 'this is likely a bug occured in LogikElement.ts')
            return false;
    }
}

function round(num: number, decimals?: string) {
    if (decimals === "ungerundet") {
        return num;
    }

    const dec = decimals ? Number.parseInt(decimals) : 2;
    const factor = Math.pow(10, dec);

    return Math.round(num * factor) / factor;
}