<template>
  <div class="icon-wrapper flex-shrink-0">
    <img
      v-if="progress <= 0 || !progress"
      src="/assets/mlIcons/progress-zero.svg"
      class="icon"
    >
    <img
      v-else-if="progress <= 33"
      src="/assets/mlIcons/progress-quarter.svg"
      class="icon"
    >
    <img
      v-else-if="progress <= 66"
      src="/assets/mlIcons/progress-half.svg"
      class="icon"
    >
    <img
      v-else-if="progress < 100"
      src="/assets/mlIcons/progress-threequarter.svg"
      class="icon"
    >
    <img
      v-else-if="progress >= 100"
      src="/assets/mlIcons/progress-completed.svg"
      class="icon"
      data-cy="completed"
    >
  </div>

<!--  {{ progress }}-->
<!--  <div class="indicator" :class="progress >= 100 ? 'completed' : ''" :data-cy="progress >= 100 ? 'completed' : 'not-completed'">-->
<!--    <div v-if="progress === 0 " class="block text-center">-</div>-->
<!--    <span v-else-if="progress < 100 ">{{ progress }}%</span>-->
<!--    <ion-icon v-else :icon="checkmark" />-->
<!--  </div>-->
</template>

<script>
import {IonIcon } from "@ionic/vue";
import { checkmark } from 'ionicons/icons';
export default {
  name: "ProgressIndicator",
  components: { IonIcon },
  props: {
    progress: {
      type: Number,
      default: undefined
    },
  },
  setup() {
    return {
      checkmark
    }
  }
}
</script>

<style scoped lang="scss">

.indicator {
  border: 1px solid $primary;
  border-radius: 8px;
  padding: 2px 4px;
  font-size: 12px;
  min-width: 35px;
  min-height: 20px;
  //color: white;
}

.icon, .icon-wrapper {
  width: 22px;
  height: 22px;
}

.completed {
  background: $primary;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  --ionicon-stroke-width: 64px;
}
</style>