import { CapacitorUpdater } from '@capgo/capacitor-updater';
import apiClient from '@/api/api';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { ref } from 'vue';
import {getPlatforms, isPlatform} from "@ionic/vue";

export function useOTA() {
  let latestVersionUrl: string | undefined = undefined;
  let latestVersionName: string | undefined = undefined;

  const isUpdating = ref(false);

  const getCurrentVersion = async () => {
    const current = await CapacitorUpdater.versionName();
    console.log('[OVER THE AIR UPDATE] Current version', current);

    // @ts-ignore
    return current.versionName || environment.BUILD_VERSION;
  };

  const getLatestVersion = async () => {
    const otaAxios = axios.create();
    otaAxios.defaults.headers = {
      'Cache-Control': 'no-cache',
    };

    if (!environment.OTA_VERSION_URL || environment.OTA_VERSION_URL === 'OTA') {
      console.info('[OVER THE AIR UPDATE] No env set for OTA_VERSION_URL')
      return false;
    }

    const res = await otaAxios({
      //@ts-ignore
      url: environment.OTA_VERSION_URL,
      method: 'GET',
    });

    console.log('[OVER THE AIR UPDATE] latest version from backend', res.data);

    latestVersionName = res.data.version;
    latestVersionUrl = res.data.url;

    return latestVersionName;
  };

  const updateAvailable = async () => {
    const current = await getCurrentVersion();
    const latest = await getLatestVersion();

    console.log(
      '[OVER THE AIR UPDATE] updateAvailable?',
      current !== latest,
      current,
      latest,
    );

    if (isPlatform('mobileweb') || isPlatform('desktop')) {
      console.log('[OVER THE AIR UPDATE] not supported on web so we return false');
      return false;
    }

    return current !== latest;
  };

  const updateNow = async () => {


    console.log('[OVER THE AIR UPDATE] found an update! updating from url...', latestVersionUrl);

    if (!latestVersionUrl) {
      return;
    }

    isUpdating.value = true;

    const version = await CapacitorUpdater.download({
      url: latestVersionUrl,
    });

    console.log('[OVER THE AIR UPDATE] latest version', version);
    const r = await CapacitorUpdater.set({
      version: version.version,
      versionName: latestVersionName,
    });
    console.log('[OVER THE AIR UPDATE] Post update', r);

    isUpdating.value = false;
  };

  return {
    updateNow,
    updateAvailable,
    isUpdating,
  };
}
