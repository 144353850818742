import apiClient from "@/api/api";
import apiErrorHelper from "@/api/apiErrorHelper";
import {
    useBestandsaufnahmeUnlockAlert,
    useBestandsaufnahmeViewAndLockAlert
} from "@/composables/Bestandsaufnahme/useBestandsaufnahmeViewAndLockAlert";
import { useProperties } from "@/composables/Property/useProperties";
import { SortTerm, SortTermActive } from "@/composables/Sort/SortTerms";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import BestandsaufnahmeModel from '@/models/ba/models/bestandsaufnahme.model';
import Immobilie from '@/models/immobilie.model';
import { logger } from "@/utilities/logging";
import { copyBaAndMergeWithImmobilie, sortArrayByProperty } from "@/utilities/sortArrayByProperty";
import { Device } from "@capacitor/device";
import { Network } from "@capacitor/network";
import { toastController } from "@ionic/vue";
import axios from "axios";
import { default as QueryString, default as qs } from 'qs';
import { computed, ref } from "vue";

interface FilterOption {
    name: string,
    id: string,
    resetOtherFilters?: boolean,
    resetSelfWhenOthersActive?: boolean,
    onlyShowWhenOthersActive?: boolean;
    dontShowWhenNotSelected?: boolean;
}

export function useBestandsaufnahmens() {

    const store = useStore();

    const isFetchingBestandsaufnahmes = ref(false);
    const shouldAbortBestandsaufnahme = ref(false);

    /**
     * Bas from vuex. Some of them may be local as well. If we are offline, we only show downloaded
     * as we can only open fully downloaded bas anyway.
     *
     * Filter out id's with $ as our plugin (vuex-orm-localforage?) produces temporary dead ba's when deleting some from localstorage.
     */
    const bestandsaufnahmes = computed(() => {

        const isOffline = computed(() => { return store.getters["app/isOffline"]; });
        const persistedBas = computed<BestandsaufnahmeModel[]>(() => { return BestandsaufnahmeModel.getters('persistedBestandsaufnahmes') })

        const sortBy = BestandsaufnahmeModel.getters("sortBy") as SortTermActive;
        const stateFilters: FilterOption[] = BestandsaufnahmeModel.getters("stateFilter");

        const showOfflineOnly = stateFilters?.find(el => el.id === 'LOCALE_offline')

        if (!isOffline.value && !showOfflineOnly) {
            let bas = BestandsaufnahmeModel.all();
            bas = bas
                .filter(el => !el.id.toString().includes('$'))
                .sort((a, b) => sortArrayByProperty(copyBaAndMergeWithImmobilie(a), copyBaAndMergeWithImmobilie(b), sortBy));
            return bas;
        }

        const offlineFilter = (bestandsaufnahme: BestandsaufnahmeModel) => {
            const property = Immobilie.find(bestandsaufnahme.immobilie);

            let searchTerm = BestandsaufnahmeModel.getters('searchTerm');
            searchTerm = searchTerm && searchTerm.toLowerCase();

            const offlineStateFilters = stateFilters?.filter(el => !el.id.startsWith('LOCALE_'))

            return (!searchTerm || Immobilie.getters('propMatchesFilter')(property, searchTerm)) &&
                (!offlineStateFilters || offlineStateFilters.length === 0 || offlineStateFilters.find(el => el.id === bestandsaufnahme.status))
        }

        return persistedBas.value
            .filter(el => el.isDownloaded && !el.id.toString().includes('$'))
            .filter(offlineFilter)
            .sort((a, b) => sortArrayByProperty(copyBaAndMergeWithImmobilie(a), copyBaAndMergeWithImmobilie(b), sortBy));
    });


    /**
     * Options to filter bestandsaufnahmes
     */
    const filterOptions: FilterOption[] = [
        { name: 'Zurücksetzen', id: 'LOCALE_reset', resetOtherFilters: true, resetSelfWhenOthersActive: true, onlyShowWhenOthersActive: true },
        { name: 'Offline verfügbar', id: 'LOCALE_offline' },
        { name: 'Angelegt', id: 'ANGELEGT' },
        { name: 'Geplant', id: 'GEPLANT' },
        { name: 'In Durchführung', id: 'IN_DURCHFUEHRUNG' },
        { name: 'Abgeschlossen', id: 'ABGESCHLOSSEN' },
        { name: 'Freigegeben', id: 'FREIGEGEBEN' },
        { name: 'Archiviert', id: 'ARCHIVIERT'},
    ]

    /**
     * Options to sort.
     */
    const supportedSortTerms: SortTerm[] = [
        { label: 'Begehungsdatum', fieldName: 'begehungsdatum' },
        { label: 'Status', fieldName: 'status' },
        { label: 'Stadt', fieldName: 'stadt', subObject: 'immobilie' },
        { label: 'Adresse', fieldName: 'adresse', subObject: 'immobilie' },
        { label: 'Ext. Objektnummer.', fieldName: 'externeObjektNr', subObject: 'immobilie' }
    ];

    /**
     * Build the query for the backend.
     */
    const getNextQuery = async () => {
        const pageSize = 25;

        await BestandsaufnahmeModel.dispatch('setPage', (BestandsaufnahmeModel.getters("page") + 1));
        const sortBy = BestandsaufnahmeModel.getters("sortBy");

        const searchString = BestandsaufnahmeModel.getters("searchTerm");
        const stateFilters = BestandsaufnahmeModel.getters("stateFilter");

        const query: any = {
            pagination: {
                page: BestandsaufnahmeModel.getters("page"),
                pageSize: pageSize
            }
        };


        if (sortBy) {
            const sortObj = { [sortBy.fieldName]: sortBy.orderBy }
            if (sortBy.subObject) {
                query.sort = { [sortBy.subObject]: sortObj }
            } else {
                query.sort = sortObj;
            }
        }

        if ((searchString ?? "") != "") {
            query.filters = {
                $and: [
                    {
                        $or: [
                            { immobilie: { name: { $containsi: searchString, } } },
                            { immobilie: { eigentuemer: { $containsi: searchString } } },
                            { immobilie: { strasse: { $containsi: searchString } } },
                            { immobilie: { plz: { $containsi: searchString } } },
                            { immobilie: { stadt: { $containsi: searchString } } },
                            { immobilie: { externeObjektNr: { $containsi: searchString } } },
                            { immobilie: { baujahr: { $containsi: searchString } } },
                        ]
                    }
                ]
            };
        }

        if (!query.filters) { query.filters = { $and: [] } }
        const backendFilter = stateFilters.filter((el: FilterOption) => !el.id.startsWith('LOCALE'));
        if (backendFilter && stateFilters.length > 0) {
            const stateQuery: any = { $or: [] }
            backendFilter.forEach((el: FilterOption) => {
                stateQuery.$or.push({ status: el.id })
            })
            query.filters.$and.push(stateQuery)
        }else{
            query.filters.$and.push({ status: { $ne: 'ARCHIVIERT' } })
        }

        

        return query;
    }

    /**
     * Stringifies the query
     */
    const getNextQueryString = async () => {
        return QueryString.stringify(await getNextQuery())
    }

    /**
     * Load Bas from IndexedDB but do not store them in vuex directly. We don't know if we want to show those properties at this state due to search/filter function;
     */
    const loadPersistedBestandsaufnahmes = async (): Promise<BestandsaufnahmeModel[]> => {
        await BestandsaufnahmeModel.dispatch("loadPersistedBestandsaufnahmes");
        return BestandsaufnahmeModel.getters("persistedBestandsaufnahmes")
    }

    const controller = new AbortController();

    /**
     * Fetch ba's from backend.
     * If we find downloaded but not local edited ba's, we load them fully instead of the preview
     */
    const CancelToken = axios.CancelToken;
    const cancelableSources: any[] = [];

    const loadBestandsaufnahmens = async () => {
        console.log("load Bestandsaufnahmes")
        logger.info('Loading BAs ...');
        if (isFetchingBestandsaufnahmes.value) {
            console.log("SOURCE CANCEL");
            logger.warn('SOURCE CANCEL');

            cancelableSources.forEach(el => {
                el.cancel('Operation canceled by the user.')
            })
        }

        isFetchingBestandsaufnahmes.value = true;

        const persistedBas = await loadPersistedBestandsaufnahmes();

        try {

            const source = CancelToken.source();
            cancelableSources.push(source)
            const status = await Network.getStatus();
            if (!status.connected) {
                throw new Error( "Network Error" );
            }
            const res = await apiClient.get(`/bestandsaufnahmes?${await getNextQueryString()}`, {
                cancelToken: source.token
            });

            const fetchedBackendBaRes = (res.data as any);


            // Ba's to push.
            const basToPush: BestandsaufnahmeModel[] = [];

            for (const ba of fetchedBackendBaRes.results) {
                await Immobilie.dispatch('addToFallbackProperties', ba.immobilie);

                ba.immobilie = ba.immobilie?.id;

                // Bas with local edits won't be updated.
                const persistedEditedBa = persistedBas?.find((local: any) => local.id === ba.id && local.isLocal)
                if (persistedEditedBa) {
                    basToPush.push(persistedEditedBa)
                    continue;
                }

                // Downloaded bas will be updated, but fully instead of preview only.
                const localDownloadedUneditedBa = persistedBas?.find((local: any) => local.id === ba.id && local.isDownloaded);
                if (localDownloadedUneditedBa) {
                    if (new Date(localDownloadedUneditedBa!.updatedAt) < new Date(ba.updatedAt)) {
                        // new update found from backend. load full ba for this.
                        // downloadedBasToUpdate.push(localDownloadedUneditedBa);
                        const newDownloadedBa = await downloadBa(undefined, ba.id, ba.immobilie);
                        newDownloadedBa && basToPush.push(newDownloadedBa);
                    } else {
                        basToPush.push(localDownloadedUneditedBa);
                    }
                    continue;
                }

                // Ba should be downloaded but isn't, e.g. if user relogged in to the app.
                // We know that the ba is not downloaded because otherwise we would have exited above.
                if (await Bestandsaufnahme.isLockedByCurrentDeviceAndUser(ba)) {
                    console.log("Found ba that should be downloaded but isnt yet", ba)
                    const newDownloadedBa = await downloadBa(undefined, ba.id, ba.immobilie);
                    newDownloadedBa && basToPush.push(newDownloadedBa);
                }

                // Ba is neither local edited, nor downloaded and unedited nor should be downloaded
                basToPush.push(ba);
            }

            await BestandsaufnahmeModel.insert({ data: basToPush });

            // Post status updates
            store.commit('app/updateBestandsaufnahmesLastRefresh');
            await BestandsaufnahmeModel.dispatch('setPage', fetchedBackendBaRes.pagination.page);
            await BestandsaufnahmeModel.dispatch('setPageCount', fetchedBackendBaRes.pagination.pageCount);
            await BestandsaufnahmeModel.dispatch('setLoaded');

            isFetchingBestandsaufnahmes.value = false;

        } catch (error: any) {
            // On Network Error, load Immobilies from local storage
            isFetchingBestandsaufnahmes.value = false;

            if (error.message === "Network Error") {

                logger.error(`Loading Bestandsaufnahmes failed: Network Error`);
                const bestandsaufnahmes = await loadPersistedBestandsaufnahmes();
                await BestandsaufnahmeModel.insert({ data: bestandsaufnahmes });

                await BestandsaufnahmeModel.dispatch('setLoaded');
            } else {
                console.error(error)
                logger.error(`Loading Bestandsaufnahmes failed: ${error}`);
            }
        }
    }

    /**
     * Mark the BA as isDownloaded
     * Mark the immobilie as isDownloaded
     * Store both to indexedDB
     *
     * Either pass ba OR ( baId AND immoId )
     * passing ba makes sense e.g. if you are calling it from currentBa
     */
    const downloadBa = async (ba?: Bestandsaufnahme | undefined, baId?: number, immoId?: number) => {
        if (ba) { logger.defaultMeta.currentBa = ba.id }
        if (baId) { logger.defaultMeta.currentBa = baId }
        if (immoId) { logger.defaultMeta.immoId = immoId }

        if (!ba && !baId) {
            console.error('Cannot download Ba: ba is undefined.')
            logger.error(`Cannot download Ba: ba is undefined`)

            return;
        }

        let baModel = BestandsaufnahmeModel.find(baId || ba!.id);

        // If ba is preview mode, download the full ba.
        if (!baModel || !baModel.fragenblocks || !baModel.fragenblocks.length) {
            const res: any = await BestandsaufnahmeModel.api().get(`/bestandsaufnahmes/${baId || ba!.id}`, { save: false })
            baModel = (res.getDataFromResponse() as any).data;
        }

        if (!baModel) {
            console.error(`Cannot download Ba: ba Model is empty`)
            logger.error(`Cannot download Ba: ba model is empty`)

            return;
        }

        baModel.isDownloaded = true;
        if (ba) { ba.isDownloaded = true; }

        await BestandsaufnahmeModel.dispatch('addToPersistedBestandsaufnahmes', baModel);
        await BestandsaufnahmeModel.dispatch('$updateLocally', { data: baModel });


        // at the moment this will never fetch a property as we send immobilies with our ba's and store them into Immobilies.
        const { considerDownloadImmobilie } = useProperties();
        await considerDownloadImmobilie(immoId || ba!.immobilie);

        console.log(`Immobilie ${immoId || ba!.immobilie} downloaded`);

        logger.info(`Immobilie ${immoId || ba!.immobilie} downloaded`);

        return baModel;
    }

    /**
     * Remove downloaded Ba from indexedDb
     * @param ba
     */
    const removeDownloadedBa = async (ba?: Bestandsaufnahme) => {
        const immobilie = ba?.immobilie && Immobilie.find(ba?.immobilie) as (Immobilie | undefined);
        if (!ba) { return console.error('removeDownloadedBa: ba is undefined') }

        const baModel = BestandsaufnahmeModel.find(ba.id);
        if (baModel && immobilie) {
            // baModel.isDownloaded = false;
            ba.isDownloaded = false;

            await BestandsaufnahmeModel.dispatch('$deleteFromLocal', baModel.id);

            await store.dispatch('currentHzba/refetchCurrentBa');

            // with current API it's not possible to  delete only from local storage but not from store, thus we need to insert afterwards again
            console.log("remove downloads add to vuex", await ba.toClassJson());

            await BestandsaufnahmeModel.dispatch('removeFromPersistedBestandsaufnahmes', baModel);
            await BestandsaufnahmeModel.insertOrUpdate({ data: await ba.toClassJson() });

            const { considerRemoveImmobilieFromDownloaded } = useProperties();
            await considerRemoveImmobilieFromDownloaded(baModel.immobilie)
        }
    }

    /**
     * Lock ba and set the state to "IN_DURCHFUEHRUNG".
     * Does not repull the ba from backend but instead, after a successful lock, sets same variables as from backend side.
     * ba - ba you want to lock.
     */
    const lockBa = async (ba: Bestandsaufnahme, t: any) => {
        // logger.defaultMeta.currentBa = ba.id};

        const { user } = useUser();
        const { uuid } = await Device.getId();

        if (user.value) { logger.defaultMeta.userId = user.value.id; }

        try {
            let toast = await toastController.create({ message: t('toasts.tryLock'), duration: 2000 })
            await toast.present();

            await apiClient.put(`/bestandsaufnahmes/lock/${ba.id}`, {
                "geraeteId": uuid
            });

            const refetchedBa = await store.dispatch('currentHzba/refetchCurrentBa', true);

            refetchedBa.status = "IN_DURCHFUEHRUNG";
            refetchedBa.bearbeitenderNutzer = user.value ?? undefined;
            refetchedBa.bearbeitendesGeraetId = uuid;
            console.log('status: ', refetchedBa.status)
            console.log("Lock ba", refetchedBa)
            logger.info(`BA ${ba.id} locked by device ${uuid}`);

            await downloadBa(refetchedBa);

            toast = await toastController.create({ message: t('toasts.lockSucceeded'), duration: 2000 })
            await toast.present();

        } catch (error) {
            ba.status = "IN_DURCHFUEHRUNG";

            const toast = await toastController.create({ message: t('toasts.lockFailed'), duration: 2000 })
            await toast.present();
            logger.error(`BA ${ba.id} could not be locked: ${error}`);

            console.error(`BA ${ba.id} could not be locked: ${error}, uuid: ${uuid}, user: ${user.value}`);
            throw error;
        }
    }

    /**
     * Unlock ba
     * Does not repull the ba from backend but instead, after a successful lock, sets same variables as from backend side.
     * ba - ba you want to unlock.
     */
    const unlockBa = async (ba: Bestandsaufnahme, t: any) => {
        const { uuid } = await Device.getId();
        const { user } = useUser();

        if (user.value) { logger.defaultMeta.userId = user.value.id; }

        try {
            await apiClient.put(`/bestandsaufnahmes/unlock/${ba.id}`, {
                "geraeteId": uuid
            });

            ba.bearbeitenderNutzer = undefined;
            ba.bearbeitendesGeraetId = undefined;

            await removeDownloadedBa(ba);
            console.info(`BA ${ba.id} unlocked by device ${uuid}`);
            logger.info(`BA ${ba.id} unlocked by device ${uuid}`);

        } catch (error) {
            await apiErrorHelper(error, t);
            console.error(`Unlocking BA by device ${uuid} failed: ${error}`);

            logger.error(`Unlocking BA by device ${uuid} failed: ${error}`);

            throw error;
        }
    }

    const lockAndEditBaAlert = async (ba: Bestandsaufnahme, t: any, abortMode?: boolean) => {
        return await useBestandsaufnahmeViewAndLockAlert(ba, t, abortMode);
    }

    const unlockBaAndDeleteLocalDataAlert = async (ba: Bestandsaufnahme, t: any) => {
        return await useBestandsaufnahmeUnlockAlert(ba, t);
    }

    const doLocalBestandsaufnahmesExists = async () => {
        return BestandsaufnahmeModel.all().find(el => el.isLocal);
    }

    const isUpgradeAvailable = async (ba: Bestandsaufnahme) => {
        const res = await apiClient.get(`/bestandsaufnahmes/isUpgradeAvailable/${ba.id}`);
        return res.data;
    }

    const migrateBa = async (ba: Bestandsaufnahme, preview: Boolean) => {
        const res = await apiClient.put(`/bestandsaufnahmes/upgradeTemplate/${ba.id}`, { preview: preview });
        return res.data
    }


    const getBackupsForBa = async (baId: number) => {
        const query = qs.stringify({
            sort: ['createdAt:desc'],
            filters: {
                bestandsaufnahme: {
                    id: {
                        $eq: baId,
                    },
                },
            },
            fields: ['createdAt'],
            pagination: {
                pageSize: 10,
                page: 1,
            },
            publicationState: 'live',
            locale: ['en'],
        }, { encodeValuesOnly: true });

        const res = await apiClient.get(`/backup-bestandsaufnahmes?${query}`)
        return res.data.data;
    }

    const runBackup = async (backupId: number) => {
        const res = await apiClient.put(`/bestandsaufnahmes/runBackup/${backupId}`)
        return res;
    }

    return {
        bestandsaufnahmes,
        loadBestandsaufnahmens,
        removeDownloadedBa,
        downloadBa,
        unlockBaAndDeleteLocalDataAlert,
        lockAndEditBaAlert,
        lockBa,
        runBackup,
        getBackupsForBa,
        isUpgradeAvailable,
        unlockBa,
        filterOptions,
        supportedSortTerms,
        doLocalBestandsaufnahmesExists,
        migrateBa
    };
}







// const loadAndUpdateFromLocalStorage = async (fetched?: BestandsaufnahmeModel[]) => {
//     const { bestandsaufnahmes } = await BestandsaufnahmeModel.dispatch("$fetchFromLocal");
//     bestandsaufnahmes?.forEach(async (ba: BestandsaufnahmeModel) => {
//         if (!ba.isLocal && ba.isDownloaded) {
//             const fetchedBa = fetched?.find(fetchedBa => fetchedBa.id === ba.id);
//             if (fetchedBa) {
//                 if (new Date(fetchedBa!.updatedAt) > new Date(ba.updatedAt)) {
//                     const res = await BestandsaufnahmeModel.api().get(`/bestandsaufnahmes/${ba.id}`, { save: false })
//                     await BestandsaufnahmeModel.dispatch('$updateLocally', { data: (res.getDataFromResponse() as any).data });
//                 }
//             }
//         }
//     })
// }


