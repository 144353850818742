import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { APhoto } from "@/models/photo/a-photo.model";
import CachedPhoto from "@/models/photo/cached-photo.model";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { isPlatform } from "@ionic/vue";
import { instanceOfPhoto } from "./get-media-url";


export const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export async function base64ImgToBlob(dataUrl: string): Promise<Blob> {
  const newImage = new Image();
  return new Promise((resolve, reject) => {
    newImage.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = newImage.width;
      canvas.height = newImage.height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(newImage, 0, 0);
      canvas.toBlob(
        (blob: Blob | null) => {
          if (blob) resolve(blob);
          else reject;
        },
        'image/jpeg',
        1
      );
    };
    newImage.onerror = reject;
    newImage.src = dataUrl;
  });
}

export const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result); // Result is a Base64 encoded string of the encoded blob
    };
    reader.readAsDataURL(blob);
});

export const savePictureLocal = async (photo: CachedPhoto): Promise<any> => {
    let base64Data: string;
    // iOS and Android
    if (isPlatform('hybrid')) {
        const file = await Filesystem.readFile({
            path: photo.path!
        });
        base64Data = file.data;
    } else { // Web
        // Fetch the photo, read as a blob, then convert to base64 format
        const response = await fetch(photo.webPath!);
        const blob = await response.blob();
        base64Data = await convertBlobToBase64(blob) as string;
    }

    const randomName = Math.random().toString(36).substr(2, 12) + "." + photo.format;

    // save base64 string on data path as filename
    const savedFile = await Filesystem.writeFile({
        path: randomName,
        data: base64Data,
        directory: Directory.Data
    });

    if (isPlatform('hybrid')) {
        // Display the new image by rewriting the 'file://' path to HTTP
        // Details: https://ionicframework.com/docs/building/webview#file-protocol
        photo.filepath = savedFile.uri;
        photo.webPath = Capacitor.convertFileSrc(savedFile.uri);

    } else {
        // Use webPath to display the new image instead of base64 since it's
        // already loaded into memory
        photo.filepath = randomName;
    }
};

const loadSaved = async (photo: CachedPhoto) => {
    // If running on the web...
    if (!isPlatform('hybrid') && photo.filepath) {
        const file = await Filesystem.readFile({
            path: photo.filepath,
            directory: Directory.Data
        });

        photo.webPath = URL.createObjectURL(b64toBlob(file.data, 'image/jpeg'));
    }
}

export const deletePhotoLocal = async (photo: CachedPhoto) => {
    if (photo.filepath) {
        try {
            const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
            await Filesystem.deleteFile({
                path: filename,
                directory: Directory.Data
            });
        } catch (err) {
            console.error('deletePhotoLocal failed', err)
        }
    }
};


/**
 * Neither Locally persisted nor uploaded image
 **/
const isImageLocalOnly = (image: APhoto) => {
    return instanceOfPhoto(image) && !image.filepath
}

export const saveHzbaPhotosLocal = (ba: Bestandsaufnahme) => {
    return new Promise((resolve, reject) => {

        // const photosUnsaved1 = ba.getFrages().map<CachedPhoto[]>(frage => (frage.eingabeMedien?.filter(image => instanceOfPhoto(image) && !image.filepath) as CachedPhoto[])).filter(a => !!a).flat(1);
        const photosUnsaved = (ba.filterPhotos(isImageLocalOnly) as CachedPhoto[]);

        // console.log("Save unsaved/local photos: ",photosUnsaved1);
        console.log("Save unsaved/local photos: ",photosUnsaved);

        try {
            Promise.all(photosUnsaved.map(async photo => {
                await savePictureLocal(photo)
            })).then(el => {
                resolve('stored images and wrote the path to the variables.')
            })
            return ba;
        } catch (error) {
            console.error(error);
            reject()
        }

    })
}

export const loadHzbaPhotosLocal = async (hzba: any) => {

    const proms: Promise<any>[] = [];

    const recursiveSearchPhotos = (obj: any, prefix: string) => {
        if (instanceOfPhoto(obj) && obj.filepath) {    // obj is photo and not saved locally
            // console.log("Load local image at", prefix);
            proms.push(loadSaved(obj));
        } else {
            Object.keys(obj).forEach((key) => {
                if (obj[key] && typeof obj[key] == "object") {
                    recursiveSearchPhotos(obj[key], prefix + "." + key)
                }
            });
        }
    }

    recursiveSearchPhotos(hzba, "hzba");

    return Promise.all(proms);
}