import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "m-4" }
const _hoisted_2 = { class: "mt-12 ml-6 mr-6 mb-12" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_signature_wrapper = _resolveComponent("signature-wrapper")!
  const _component_AButton = _resolveComponent("AButton")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_toolbar, {
          title: _ctx.t('hzba.vorschau.bescheinigungTitel'),
          "enable-desktop-nav": false
        }, {
          trailing: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  "data-cy": "photo-preview-close",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      class: "white-icon",
                      icon: _ctx.arrowBack
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_signature_wrapper, {
              ref: "signatureWrapper",
              ba: _ctx.ba,
              "is-readonly": _ctx.isReadonly,
              onOnSignaturesComplete: _ctx.onSignaturesComplete
            }, null, 8, ["ba", "is-readonly", "onOnSignaturesComplete"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            (!_ctx.isReadonly && _ctx.ba?.unterschrifts?.length !== 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.t("hzba.vorschau.bescheinigungInfo")), 1))
              : _createCommentVNode("", true),
            (!_ctx.isReadonly && _ctx.ba?.unterschrifts?.length === 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.t("hzba.vorschau.unterschriftLoeschen")), 1))
              : _createCommentVNode("", true),
            (_ctx.isReadonly && (_ctx.ba.status === 'ABGESCHLOSSEN'))
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.t("hzba.vorschau.hzbaAbgeschlossenInfo")), 1))
              : _createCommentVNode("", true),
            (_ctx.isReadonly && !(_ctx.ba.status === 'ABGESCHLOSSEN'))
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.t("hzba.vorschau.unterschriftInfoReadOnly")), 1))
              : _createCommentVNode("", true),
            (!_ctx.isReadonly)
              ? (_openBlock(), _createBlock(_component_AButton, {
                  key: 4,
                  class: "text-lg py-5 px-8 mr-3",
                  "btn-primary": true,
                  shape: "round",
                  disabled: !_ctx.isSignatureComplete,
                  onClick: _ctx.submitHzba
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.bestaetigen")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true),
            (!_ctx.isReadonly)
              ? (_openBlock(), _createBlock(_component_AButton, {
                  key: 5,
                  class: "text-lg py-5 px-8",
                  "btn-primary": true,
                  fill: "flat",
                  onClick: _ctx.addSignature
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.unterschriftHinzufuegen")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}