<template>
  <div class="fixed acon-gradient update-screen">
    <div class="flex justify-center flex-col">
      <img
        src="../../../public/assets/img/svg/MovingLayers_Logo_RGB_Aubergine.svg"
        alt="acon_text"
        class="acon_logo_white m-4 mt-8 lg:m-16"
      >
      <div class="mt-8 text-center">
        We are optimizing your app experience.
      </div>
      <ion-spinner
        name="dots"
        duration="1000"
        class="inline-block m-auto mt-2"
      />
    </div>
  </div>
</template>

<script>
import { IonSpinner } from "@ionic/vue";

export default {
  name: "UpdateScreen",
  components: {
    IonSpinner,
  }
}
</script>

<style scoped lang="scss">


.acon-gradient {
  background: linear-gradient(101deg, white, white);
}

.update-screen {
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

</style>