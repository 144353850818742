<template>
  <div
    class=" 
      btn-secondary
      inline-flex
      items-center
      px-4
      mr-2.5
      rounded-full
      text-sm
      font-medium
      border border-solid
    "
    :class="small ? 'py-1' : 'py-1.5'"
  >
    <ion-icon
      :icon="cloudOfflineOutline"
      size="small"
    />
  </div>
</template>

<script>


import {
  cloudOfflineOutline,
} from "ionicons/icons";
import {IonIcon} from "@ionic/vue";
export default {
  name: "CloudOfflineIcon",
  components: {IonIcon},
  props: {
    small: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return { cloudOfflineOutline }
  }
}
</script>

<style scoped>

</style>