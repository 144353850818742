
<template>
  <div class="status-item">
    <!--    <progress-indicator :progress="currentClass === 'done' ? 100 : 0" style="margin-right: 8px"/>-->
    <div
      class="step"
      :class="currentClass"
    >
      {{ step }}
    </div>
    <div
      class="text"
      :class="currentClass"
    >
      {{ text }}
    </div>
  </div>
</template>
<script>
import ProgressIndicator from "@/components/ProgressIndicator";
export default {
  components: {ProgressIndicator},
  props: {
    currentClass: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    step: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style lang="scss">
.status-item {
  //flex-wrap: nowrap;
  display: flex;
  padding: 8px 16px 8px 0;
  font-size: 14px;
  align-items: center;
}


.step {
  margin-right: 8px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  color: $grey-500;
  border: 1px solid $grey-500;
  display: flex;
  align-items: center;
  justify-content: center;

  &.done, &.doing {
    background: $grey-500;
    color: white;
    //background: $aconBlue;
  }
}


.text {
  &.doing {
    color: $grey-800;
  }

  &.doing {
    font-weight: 600;
  }
}


</style>