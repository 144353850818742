import { APhotoUploadTask, uploadPhoto } from "@/api/UploadApi";
import { SortTerm, SortTermActive } from "@/composables/Sort/SortTerms";
import { useStore } from "@/composables/useTypedStore";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import { APhoto } from "@/models/photo/a-photo.model";
import { instanceOfPhoto } from "@/utilities/get-media-url";
import { sortArrayByProperty } from "@/utilities/sortArrayByProperty";
import { generateUUID } from "@ionic/cli/lib/utils/uuid";
import QueryString from 'qs';
import { computed } from "vue";

/**
 *
 */
export function useProperties() {

    const store = useStore();
    const immoPhotoUid = generateUUID();

    /**
     * Properties from vuex. Some of them may be local as well. If we are offline, we only show downloaded properties
     * as we can only open fully downloaded properties anyway.
     */
    const properties = computed(() => {

        const isOffline = computed(() => { return store.getters["app/isOffline"]; });
        const persistedProps = computed<Immobilie[]>(() => { return Immobilie.getters('persistedProperties')})

        const sortBy = Immobilie.getters("sortBy");

        if (!isOffline.value) {
            const filteredSortedProperties = Immobilie.query().with("bestandsaufnahmes").get()
                .filter(el => !el.id.toString().includes('$'))
                .sort((a,b) => sortArrayByProperty(a,b, getCurrentSortTermWithFunction(sortBy)));

            return filteredSortedProperties;
        }

        const offlineFilter = (property: Immobilie) => {
            let searchTerm = Immobilie.getters('searchTerm');
            searchTerm = searchTerm && searchTerm.toLowerCase();

            if (!searchTerm) { return true; }
            return Immobilie.getters('propMatchesFilter')(property, searchTerm);
        }
        return persistedProps.value ? persistedProps.value
            .filter(el => el.isDownloaded)
            .filter(el => !el.id.toString().includes('$'))
            .filter(offlineFilter)
            .sort((a, b) => sortArrayByProperty(a,b, getCurrentSortTermWithFunction(sortBy))) : [];
    });


    /**
     * Build the query for the backend.
     */
    const getNextQuery = async () => {
        const pageSize = 150;
        await Immobilie.dispatch('setPage', (Immobilie.getters("page") + 1));
        const sortBy = Immobilie.getters("sortBy") as SortTermActive;

        const searchString = Immobilie.getters("searchTerm");

        const query: any = {
            pagination: {
                page: Immobilie.getters("page"),
                pageSize: pageSize
            }
        };

        if (sortBy) {
            const sortObj = { [sortBy.fieldName]: sortBy.orderBy }

            if (sortBy.subObject) {
                const splittedKeys = sortBy.subObject?.split('.');
                if (splittedKeys.length === 1) query.sort = { [sortBy.subObject]: sortObj}
                if (splittedKeys.length === 2) query.sort = { [splittedKeys[0]]: { [splittedKeys[1]]: sortObj } }

                console.log("query sort", query.sort);
            } else {
                query.sort = sortObj;
            }
        }

        if ((searchString ?? "") != "") {
            query.filters = {
                $or: [
                    { name: { $containsi: searchString } },
                    { eigentuemer: { $containsi: searchString } },
                    { strasse: { $containsi: searchString } },
                    { plz: { $containsi: searchString } },
                    { stadt: { $containsi: searchString } },
                    { externeObjektNr: { $containsi: searchString } },
                    { baujahr: { $containsi: searchString } },
                ]
            }
        }

        return query;
    }


    /**
     * Stringifies the query
     */
    const getQueryString = async () => {
        return QueryString.stringify(await getNextQuery())
    }

    /**
     * Sortable items
     */
    const supportedSortTerms: SortTerm[] = [
        { label: 'Stadt', fieldName: 'stadt'},
        { label: 'Straße', fieldName: 'strasse'},
        { label: 'Ext. Objektnummer', fieldName: 'externeObjektNr'}
    ];
    // When using supportedSortTerms in vue, the function gets lost (probably because the var gets parsed to string somehwere and then it's converted back)
    const getCurrentSortTermWithFunction = (currentSortTerm: SortTermActive): SortTermActive => {
        return { ...currentSortTerm, localSubobjectSortFunction: supportedSortTerms.find(el => el.label === currentSortTerm.label)?.localSubobjectSortFunction }
    }

    /**
     * Load Properties from IndexedDB but do not store them in vuex directly. We don't know if we want to show those properties at this state due to search/filter function;
     */
    const loadPersistedProperties = async (): Promise<Immobilie[]> => {
        await Immobilie.dispatch("loadPersistedProperties");
        return Immobilie.getters("persistedProperties")
    }

    /**
     * Sanitize immobilies after fetching from backend.
     */
    const sanitizePropertyAfterFetch = (prop: Immobilie) => {
        prop.bestandsaufnahmes?.forEach(ba => {
            ba.immobilie = prop.id;
        })
    }

    /**
     * Persist immobilie with id
     */
    const downloadImmobilie = async (id: number) => {

        const immobilie: Immobilie | undefined = Immobilie.find(id) as (Immobilie | undefined);

        if (immobilie) {
            immobilie.isDownloaded = true;
            await Immobilie.dispatch('$updateLocally', { data: immobilie });
            await Immobilie.dispatch('addToPersistedProperties', immobilie);
        } else {
            console.error("downloadImmobilie: immobilie is empty and could not be downloaded.")
        }
    }

    /**
     * Fetch immobilies from backend
     * Called after login, when on /bas or /properties page
     */
    const loadImmobilienPreviews = async () => {

        const persistedProperties = await loadPersistedProperties();

        try {
            if (!Immobilie.getters("sortBy")) { await Immobilie.dispatch("setSortBy", 'externeObjektNr'); }

            const res = await Immobilie.api().get(`/immobilies?${await getQueryString()}`, { save: false });

            const resProperties = (res.getDataFromResponse() as any);

            if (!resProperties.results || resProperties.results.length === 0) {
                console.log("Fetched Properties: empty result")
                return;
            }

            const fetchedProperties = resProperties.results as Immobilie[];

            const propsToPush: Immobilie[] = [];
            for (const prop of fetchedProperties) {
                sanitizePropertyAfterFetch(prop);

                const foundPersisted = persistedProperties?.find((localProp: any) => localProp.id === prop.id);
                if (foundPersisted) {
                    const immobilie = new Immobilie(prop);

                    if (immobilie && foundPersisted.updatedAt < immobilie.updatedAt) {
                        /*
                         * Persisted Property is not up-to-date anymore
                         */

                        immobilie.isDownloaded = true;

                        // console.log("local prop found, replace with newer one", prop, immobilie);
                        // // TODO replace with full
                        // console.warn("TODO - replace with full prop instead of preview prop (local prop found, replace with newer one)")
                        // await Immobilie.dispatch('$updateLocally', { data: immobilie });
                        const immo = await fetchFullProperty(immobilie.id);
                        await downloadImmobilie(immo.id); // todo is this still working?
                    } else {
                        // console.log("persisted prop found", foundPersisted);
                        /**
                         * Persisted property is up-to-date, so push persisted.
                         */
                        propsToPush.push(foundPersisted);
                    }
                } else {
                    /**
                     * No persisted prop found for this id.
                     * But is the property already loaded in vuex? if not, push.
                     */
                    // console.log("no persisted prop found. ", prop);
                    const foundLocal = Immobilie.all().find((localProp: any) => localProp.id === prop.id);
                    if (!foundLocal) {
                        // console.log("pushing property as no local found", prop)
                        propsToPush.push(prop);
                    }
                }
            }

            await Immobilie.insert({ data: propsToPush });

            // console.log("page count", resProperties.pagination.pageCount);

            //Post status updates
            await Immobilie.dispatch('setPage', resProperties.pagination.page);
            await Immobilie.dispatch('setPageCount', resProperties.pagination.pageCount);
            await Immobilie.dispatch('setLoaded');

        } catch (error) {
            // On Network Error, load Immobilies from local storage
            if (error.message === "Network Error") {
                const immobilies = await loadPersistedProperties();
                await Immobilie.insert({ data: immobilies });

                await Immobilie.dispatch('setLoaded');
            } else {
                console.error(error)
            }
        }
    }

    /**
     * Checks wether a property is downloaded or not.
     */
    const isPropertyDownloaded = async (propId: number) => {
        await loadPersistedProperties();
        const propsDownloaded = Immobilie.getters("persistedProperties")
        const im = propsDownloaded.find((el: any) => el.id === propId);
        if (!im) return false;
        return im.isDownloaded;
    }

    /**
     * Load full immobilie
     */
    const fetchFullProperty = async (id: number) => {
        try {
            const wasDownloaded = isPropertyDownloaded(id);
            const res = await Immobilie.api().get(`/immobilies/${id}`, { save: false })
            const immo = (res.getDataFromResponse() as any).data;
            sanitizePropertyAfterFetch(immo);
            await Immobilie.insertOrUpdate({ data: immo });

            if (wasDownloaded) { // TODO: refactor, it is always truthy, as isPropertyDownloaded is called without await.
                await downloadImmobilie(id);
            }
            return immo;
        } catch (error: any) {
            if (error.message === "Network Error") {
                console.log("Cannot load full immobilie, no connection!");
            } else {
                throw error;
            }
        }
    }

    /**
     * Todo refactor
     * at the moment this will never be executed as we send immobilies with our ba's.
     */
    const considerDownloadImmobilie = async (immobilieId?: number) => {
        if (!immobilieId) {
            console.error('Cannot download Property of Ba as property is undefined.')
            return;
        }

        let immobilie: Immobilie | undefined = Immobilie.find(immobilieId) as (Immobilie | undefined);

        if (!immobilie) {
            await fetchFullProperty(immobilieId);
            immobilie = Immobilie.find(immobilieId) as (Immobilie | undefined);
        }

        if (!immobilie) {
            console.error('Could not find and download immobilie with id', immobilieId);
            return;
        }

        await downloadImmobilie(immobilie.id);
    }

    /**
     * Check if there are some ba's left that are offline. If not, remove from downloaded.
     */
    const considerRemoveImmobilieFromDownloaded = async (immobilieId: number) => {
        const bas = BestandsaufnahmeModel.query().where("immobilie", immobilieId).all();

        const localBas = bas.filter(ba => ba.isDownloaded || ba.isLocal);

        if (!localBas || localBas?.length == 0) {  // delete Immobilie only if no other downloaded BA has same Immobilie
            const im = Immobilie.find(immobilieId);
            if (!im) {
                console.warn("considerRemoveImmobilieFromDownloaded: Could not find immobilie", immobilieId);

                return;
            }

            im!.isDownloaded = false;

            await Immobilie.dispatch('removeFromPersistedProperties', im);
            await Immobilie.dispatch('$deleteFromLocal', im.id);
            await Immobilie.insertOrUpdate({ data: im as any });
        }
    }

    /**
     * Are properties loaded yet?
     */
    const propertiesLoaded = computed(() => {
        return Immobilie.getters("loaded") || (properties.value && properties.value.length > 0) }
    );


    const getImmoPhototask = ( property: Immobilie, type: string, image: APhoto ): APhotoUploadTask => {

        return {
            field: type,
            image,
            ref: type,
            api: "api::immobilie.immobilie",
            uid: immoPhotoUid,
            status: instanceOfPhoto(property.vorschaubild) ? "preparing" : "success",
            instanceId: property.id,
            fileName: property.id + Date.now().toString() + ".jpeg"
        }
    }

    const createVorschauBildTasks = async () => {
        const persistedProperties = await loadPersistedProperties();
        const vorschauBildTasks: APhotoUploadTask[] = [];
        const bildTasks: APhotoUploadTask[] = [];
        const updatedPropertyIds: number[] = [];

        persistedProperties.forEach((property: Immobilie) => {
            // @ts-ignore
            if ( property.vorschaubild && property.vorschaubild.webPath ) {
                vorschauBildTasks.push( getImmoPhototask( property, "vorschaubild", property.vorschaubild ) );
                updatedPropertyIds.push(property.id);
            } 
            if ( property.bilder && property.bilder.length && property.bilder.length > 0 ) {
                property.bilder.forEach((bild: any) => {
                    if ( bild.webPath) {
                        bildTasks.push( getImmoPhototask( property, "bilder", bild ) );
                        
                        const existingPersistedBaIndex = updatedPropertyIds.findIndex(( id: number ) => id === property.id );

                        if ( existingPersistedBaIndex === -1 ) {
                            updatedPropertyIds.push( property.id );
                        }
                    }
                });
            
            }
        });

        return {
            vorschauBildTasks,
            bildTasks,
            updatedPropertyIds
        }
    }

    const sendImmophotos = async ( tasks: APhotoUploadTask[] ) => {
        return await Promise.all(
            tasks.map((el: APhotoUploadTask) => {
                return uploadPhoto(
                    // @ts-ignore
                    el.image,
                    el.api,
                    el.instanceId?.toString(),
                    el.field,
                    el.fileName,
                    "immobilie"
                    );
            })
        );
    }
    const sendPersistedImages = async () => {
        const {
            vorschauBildTasks,
            bildTasks,
            updatedPropertyIds
        } = await createVorschauBildTasks();
        let uploadVorschauBildResults: any = [];
        
        try {
            uploadVorschauBildResults  = await sendImmophotos( vorschauBildTasks );

        } catch (error) {
            console.error("sendImmophotos vorschaubild error", error);
            throw error;

        }

        try {
            await sendImmophotos( bildTasks );

        } catch (error) {
            console.error("sendImmophotos bilder error", error);
            throw error;
        }

        try {
            await Promise.all(
                uploadVorschauBildResults.map((el: any, index: number) => {
                    return Immobilie.api().post("/immobilie/addImmoPhoto", {
                        propertyId: vorschauBildTasks[index].instanceId?.toString(),
                        imageId: el.data[0].id
                    });
                })
            );
        } catch (error) {
            console.error("addImmoPhoto error", error);
            throw error;
        }
       
        try {
            await Promise.all(
                updatedPropertyIds.map((id: number) => {
                    return fetchFullProperty(id);
                    
                })
            );
        }
        catch (error) {
            console.error("fetchFullProperty error", error);
            throw error;
        }
       
    }

    return {
        properties,
        loadImmobilienPreviews,
        considerRemoveImmobilieFromDownloaded,
        considerDownloadImmobilie,
        fetchFullProperty,
        propertiesLoaded,
        supportedSortTerms,
        sendPersistedImages
    };
}








