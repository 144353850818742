<template>
  <div
    class="block mt-2 pb-4"
    style="overflow-x: auto"
  >
    <div
      v-for="option in filterOptions"
      :key="option.id"
      class="inline-block mb-1"
    >
      <div
        v-if="!option.onlyShowWhenOthersActive || selectedFilters.filter(el => el.id !== option.id).length > 0"
        class="pill"
        :class="selectedFilters && selectedFilters.find(el => el.id === option.id) ? 'active' : ''"
        @click="() => toggleOption(option)"
      >
        {{ translatedHzbaStatus(option.id,t) }}
      </div>
    </div>
  </div>
</template>

<script>
import {translatedHzbaStatus} from "@/models/ba/interfaces/IBestandsaufnahme";
import { useI18n } from "vue-i18n";
export default {
  name: "Filters",
  props: {
    filterOptions: {
      type: Array,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    }
  },
emits: ['update:selectedFilters'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const toggleOption = (option) => {
      const copiedFilter = JSON.parse(JSON.stringify(props.selectedFilters));

      const index = copiedFilter.findIndex(el => el.id === option.id)
      if (index >= 0) {
        copiedFilter.splice(index, 1);
      } else {

        if (option.resetOtherFilters) {
          while (copiedFilter.length > 0) {
            copiedFilter.pop();
          }
        }

        for (let i = copiedFilter.length-1; i >= 0; i--) {
          const item = copiedFilter[i];
          if (item.resetSelfWhenOthersActive) {
            copiedFilter.splice(i, 1)
          }
        }

        copiedFilter.push(option);
      }

      emit('update:selectedFilters', copiedFilter);
    }


    return {
      toggleOption,
      translatedHzbaStatus,
      t
    }
  }
}
</script>

<style scoped lang="scss">

.pill {
  padding: 4px 8px;
  background: #F8F9FB;
  border-radius: 12px;
  margin-right: 6px;
  font-size: 14px;
  user-select: none;
  border: 1px solid #efefef;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }


  &.active {
    border: 1px solid #505050;
  }
}

</style>