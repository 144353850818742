import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hzba_card = _resolveComponent("hzba-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid grid-cols-1 lg:p-0 sm:grid-cols-1 md:grid-cols-2 gap-5", [
      _ctx.propertyView ? 'lg:grid-cols-2' : 'lg:grid-cols-3',
      _ctx.propertyView ? 'xl:grid-cols-3' : 'xl:grid-cols-4',
    ]
    ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hzbas, (ba) => {
      return (_openBlock(), _createBlock(_component_hzba_card, {
        key: ba.id,
        ba: ba
      }, null, 8, ["ba"]))
    }), 128))
  ], 2))
}