import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_label = _resolveComponent("input-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_unit_chip = _resolveComponent("unit-chip")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_geo_json_input = _resolveComponent("geo-json-input")!
  const _component_input_error = _resolveComponent("input-error")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    lines: "none",
    "data-cy": "text-input-item"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, { class: "p-0 mb-8" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-wrap d-flex items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_input_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { class: "ion-float-right" }),
              _createVNode(_component_unit_chip, {
                "show-unit": "",
                unit: _ctx.unit
              }, null, 8, ["unit"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { class: "mb-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_geo_json_input, {
                identifier: _ctx.identifier,
                "input-json": _ctx.modelValue,
                "map-config": _ctx.mapConfig,
                "min-max": _ctx.minMax,
                readonly: _ctx.readonly,
                "onUpdate:inputJson": _ctx.onChanged
              }, null, 8, ["identifier", "input-json", "map-config", "min-max", "readonly", "onUpdate:inputJson"])
            ]),
            _: 1
          }),
          _createVNode(_component_input_error, { errors: _ctx.errors }, null, 8, ["errors"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}