<template>
  <ion-item
    button
    detail
    lines="none"
    @click="$emit('click')"
  >
    <ion-grid>
      <ion-row class="flex-nowrap d-flex items-center">
        <input-label>{{ label }}</input-label>
        <div
          style="margin-right:1rem;"
        >
          chatChip
          <!--          <ion-icon-->
          <!--            size="small"-->
          <!--            :icon="chatbubbleEllipsesOutline"-->
          <!--          />-->
        </div>


        <div>
          <ion-chip color="warning">
            <ion-label
              class="info1_label"
              color="dark"
            >
              chiptitle
            </ion-label>
          </ion-chip>
        </div>
      </ion-row>
    </ion-grid>
  </ion-item>
</template>

<script>
import { IonItem, IonIcon, IonLabel, IonChip, IonGrid, IonRow, IonCol} from "@ionic/vue";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
export default {
  name: "HzbaModalItem",
  components: {
    InputLabel,
    IonItem, IonIcon, IonLabel, IonChip, IonGrid, IonRow, IonCol
  },
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>