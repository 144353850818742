<template>
  <div>
    <iframe
      height="100%"
      width="100%"
      :src="`${getFilePath}`"
    />
  </div>
</template>

<script>
import { computed} from "vue";
export default {
  name: "PDFViewer",
  props: ["fileName" ],
  setup(props) {
    const viewerPath = `../../../lib/pdfjs-2/web/viewer.html`;

    const getFilePath = computed(() => {
      if (props.fileName !== "") {
        return viewerPath + "?file=" + props.fileName;
      }
      return viewerPath;
    });
    return {getFilePath}
  },
};
</script>
<style scoped>
div {
  width: 100%;
  height: 100%;
  min-width: 400px;
}
</style>