import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import { useProperties } from "@/composables/Property/useProperties";
import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import { Network } from "@capacitor/network";
import { toastController } from "@ionic/vue";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

interface HzbaSubpage {
    onIonViewWillEnter: Function;
    onIonViewWillLeave?: Function;
    // onIonViewDidLeave: Function;
}

export default function useBestandsaufnahmeSubpage(params: HzbaSubpage) {
    const store = useStore();
    const router = useRouter();

    let alert: any;

    const { t } = useI18n({ useScope: 'global' })

    const localSubpage = ref();

    const baId = computed<string>(() => { return router.currentRoute.value.params.baId && router.currentRoute.value.params.baId.toString(); })
    const baSubpageName = computed(() => { return localSubpage.value === undefined ? router.currentRoute.value.params.subpage : localSubpage.value; })

    const ba = computed<Bestandsaufnahme | undefined>(() => {
        return store.state.currentHzba.currentBa;
    })

    const isHzbaLoaded = computed<boolean>(() => !!ba.value?.identifier)

    const immobilie = computed(() => { return ba.value && Immobilie.find(ba.value.immobilie); });

    const goBackUrl = computed(() => {
        const rec = store.state.app.recentRoute;

        if (rec.path && rec.path !== '/') {
            return rec.path;
        } else {
            return `/ba/${baId.value}`
        }
    })


    const setCurrentHzba = async () => {
        if (!baId.value) {
            console.error('No hzbaId or propertyId given.');
            return
        }

        const id: number = parseInt(baId.value);
        if (store.state.currentHzba.currentBa && id === store.state.currentHzba.currentBa.id) {
            console.log("Hzba already setup. Return here.")
            return;
        }

        const loaded = computed(() => BestandsaufnahmeModel.getters("loaded"));

        const setBa = async () => {
            // let localBa = BestandsaufnahmeModel.find(id);
            const localBas = await BestandsaufnahmeModel.dispatch("loadPersistedBestandsaufnahmes")
            let localBa = localBas.find((el: any) => el.id === id);

            if (!localBa || !localBa.isLocal) {
                // Fetch full Ba from Backend if BA from store is not Local, if it is, we know it was fully loaded before
                try {
                    const status = await Network.getStatus();
                    if (!status.connected) {
                        await store.commit('currentHzba/setUploadStatus', 'errorNoInternetConnection');
                        throw new Error( "Network Error" );
                    }
                    const res = await BestandsaufnahmeModel.api().get(`/bestandsaufnahmes/${id}`, { save: false })
                    await BestandsaufnahmeModel.insertOrUpdate({ data: (res.getDataFromResponse() as any).data });
                } catch (err) {
                    if (err.message === "Network Error") {
                        if (!localBa || !localBa.isDownloaded) {
                            console.error("Fetch BE error", err);

                            const toast = await toastController.create({
                                message: t('toasts.keineVerbindungMitBackendHergestellt'),
                                duration: 2000
                            })
                            await toast.present();

                            await router.push('/bas')
                        } else {
                            // do nothing as we have the ba downloaded.
                        }
                    } else {
                        console.error("HZBA_CRITICAL_ERROR HZBA_LOGGING (FRONTEND) - Error fetching BA in setBa", err);
                    }
                }

                localBa = BestandsaufnahmeModel.find(id);
            }

            if (!localBa) {
                console.error(`Hzba with id ${baId.value} not found.`)

                const toast = await toastController.create({
                    message: t('toasts.keineBaGefundenMitDieserId'),
                    duration: 2000
                })
                await toast.present();

                await router.push('/bas')
            }

            await store.dispatch("currentHzba/setCurrentHzba", localBa);

            const useBa = useBestandsaufnahmens();
            alert = ba.value && await useBa.lockAndEditBaAlert(ba.value, t);

            if (ba.value && !Immobilie.find( ba.value.immobilie) ) {
                const { fetchFullProperty } = useProperties();
                await fetchFullProperty( ba.value.immobilie );
            }
        }

        if (loaded.value) {
            setBa();
        } else {
            watch(() => BestandsaufnahmeModel.getters("loaded"), async (value) => {
                setBa();
            });
        }
    }

    params.onIonViewWillEnter(async () => {
        store.commit("app/hideBottomNavigation");

        if (baId.value.toString() !== store.state.currentHzba.currentBa?.id.toString()) {
            await store.dispatch("currentHzba/setCurrentHzba", null);
            await setCurrentHzba();
        }
    });

    params.onIonViewWillLeave && params.onIonViewWillLeave(() => {
        alert && alert.value && alert.value.dismiss && alert.value.dismiss();
        return true;
    })

    return {
        isHzbaLoaded,
        baId,
        hzba: store.state.currentHzba.currentHzba,
        ba,
        baSubpageName,
        immobilie,
        goBackUrl,
        localSubpage,
    }

}